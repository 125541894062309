import { DocumentMode } from '@root/@types/types';

export const documentModeRegex = {
  [DocumentMode.SUBMISSIONS]: /^\/contracts\/([A-Za-z0-9-]+)(\/(contract|form)?)?$/,
  [DocumentMode.ENDORSEMENTS]: /^\/contracts\/([A-Za-z0-9-]+)\/endorsements(\/[A-Za-z0-9-]+)*\/?$/,
  [DocumentMode.TEMPLATES]: /\btemplates[a-zA-Z]*/,
  [DocumentMode.BRANDING]: /\bbranding[a-zA-Z]*/,
};

export const isSubmission = (pathname: string): boolean => documentModeRegex.submissions.test(pathname);
export const isEndorsement = (pathname: string): boolean => documentModeRegex.endorsements.test(pathname);
export const isTemplate = (pathname: string): boolean => documentModeRegex.templates.test(pathname); //|| pathname.startsWith('/templates');
export const isBranding = (pathname: string): boolean => documentModeRegex.branding.test(pathname);

export interface DocumentModeResult {
  ids: string[];
  mode: DocumentMode;
}

export const getDocumentMode = (pathname?: string): DocumentModeResult => {
  let url = pathname ?? window.location.pathname;

  const parts: string[] = url.split('/').filter(Boolean);

  const id1 = parts[1];
  const id2 = parts[3];

  let mode = DocumentMode.OTHER;
  let ids: string[] = [];

  switch (true) {
    case isTemplate(url):
      mode = DocumentMode.TEMPLATES;
      ids = [id1];
      break;

    case isEndorsement(url):
      mode = DocumentMode.ENDORSEMENTS;
      ids = [id1, id2];
      break;

    case isSubmission(url):
      mode = DocumentMode.SUBMISSIONS;
      ids = [id1];
      break;
    case isBranding(url):
      mode = DocumentMode.BRANDING;
      ids = [id1];
      break;
    default:
      break;
  }

  return { mode, ids: ids.filter(Boolean) };
};
