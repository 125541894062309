import { COMMENTS_BADGE_Z_INDEX } from '@constants/z-indices';
import { cva } from 'class-variance-authority';

export const baseBlockBodyClasses = [
  'relative',
  'flex',
  'px-mrc-block-padding',
  'py-3',
  'transition-colors',
  'duration-500',
  'cursor-text',
  'opacity-100',
  'page-A4-width',
  'block-min-height',
];

export const leftBorderClasses = cva(
  [
    'transition',
    'duration-500',
    'ease-in-out',
    'absolute',
    'w-1',
    'h-full',
    'left-0',
    'top-0',
    'opacity-0',
    COMMENTS_BADGE_Z_INDEX,
  ],
  {
    variants: {
      editing: { false: null, true: null },
      editable: { true: null, false: null },
      forceHoverEffect: { true: ['!bg-info-300', 'opacity-100'], false: null },
      disableHoverEffect: { true: ['!bg-transparent', '!group-hover:opacity-0'], false: null },
    },
    compoundVariants: [
      {
        editing: false,
        editable: false,
        className: ['bg-info-300', 'opacity-100'],
      },
      {
        editable: true,
        editing: false,
        className: ['group-hover:opacity-100', 'group-hover:bg-primary-400', 'bg-transparent'],
      },
      {
        editable: true,
        editing: true,
        className: ['bg-primary-600', '!opacity-100'],
      },
    ],
  },
);

export const blockViewBodyClasses = cva(baseBlockBodyClasses, {
  variants: {
    editing: {
      true: ['!p-0', 'drop-shadow-2xl'],
      false: [],
    },
    active: { true: ['bg-primary-100', 'hover:bg-primary-100'] },
    highlighted: { true: ['bg-primary-50'] },
    pdf: { false: ['border-b'], true: ['!pb-0'] },
    disabled: { true: ['pointer-events-none'], false: ['pointer-events-auto'] },
    conditionallyNotVisible: {
      true: ['bg-info-100', 'cursor-default'],
      false: [],
    },
    lockedFromEditing: { true: ['cursor-default'] },
    disableMentionHighlight: { true: ['cursor-default disable-mention-highlight'] },
    hasBeenMovedByRenewal: { true: ['bg-[#FFFBF7]'] },
  },
  defaultVariants: {
    editing: false,
    highlighted: false,
    pdf: false,
    disabled: false,
  },
});

export const indicatorIconsClasses = [
  'py-3',
  'absolute',
  'top-0',
  'right-0',
  'flex',
  'justify-evenly',
  'text-info-500',
  'w-mrc-block-padding',
  'bg-transparent',
];
