import { resolveSchemas } from '@helpers/resolveSchema';
import type { JSONSchema } from '@root/@types/schema';
import type { FormError } from '@root/@types/types';
import type { ResolvedSchemaTree } from '@root/helpers/schema';
import {
  flattenResolvedSchemaTree,
  resolveRefs,
  resolveSchemaTreeFromForm,
  schemaTreeToFlatList,
} from '@root/helpers/schema';
import { compiledSchema } from '@root/helpers/schema/ajv';
import schemaCDR from '@root/helpers/schema/schema-cdr.json';
import { createSchemaValidation } from '@root/helpers/schema/schema-store-validation';
import type { SchemaTreeToFlatListResult } from '@root/helpers/schema/schemaTreeToFlatList';
import { isTemplatePath } from '@utils/app-paths';
import { cloneDeep, isEqual } from 'lodash-es';
import { create } from 'zustand';

import { useDatapointsStore } from './datapoints.store';
import type { DataItems, SchemaValidation, SectionValidationState } from './types';

export type SchemaStoreSlice = {
  dataItems: DataItems | null;
  errors: FormError[] | null;
  findField: (
    id: string,
    shouldIncludeParentNodes?: boolean,
    shouldIncludeConditionalFields?: boolean,
  ) => SchemaTreeToFlatListResult | null | undefined;
  getSectionValidationState: (id: string) => SectionValidationState;
  isValid: boolean;
  refreshResolvedSchema: (formValues: Record<string, unknown>) => void;
  resetSchemaStore: () => void;
  resolvedRefsSchema: JSONSchema | null;
  resolvedSchema: JSONSchema | null;
  resolvedSchemas: JSONSchema[] | null;
  schema: JSONSchema;
  schemaTree: ResolvedSchemaTree[] | null;
  schemaTreeWithConditionalFields: ResolvedSchemaTree[] | null;
  schemaTreeList: SchemaTreeToFlatListResult[] | null;
  schemaTreeListWithParents: SchemaTreeToFlatListResult[] | null;
  schemaTreeListWithConditionalFields: SchemaTreeToFlatListResult[] | null;
  schemaValidation: SchemaValidation;
  updateSchemaStore: (dataItems: DataItems) => void;
  validate: () => void;
};

export const useSchemaStore = create<SchemaStoreSlice>((set, get) => ({
  schema: schemaCDR as unknown as JSONSchema,
  resolvedRefsSchema: null,
  dataItems: null,
  schemaTree: null,
  schemaTreeWithConditionalFields: null,
  errors: null,
  schemaTreeList: null,
  schemaTreeListWithParents: null,
  schemaTreeListWithConditionalFields: null,
  resolvedSchema: null,
  resolvedSchemas: null,
  schemaValidation: {},
  isValid: false,
  refreshResolvedSchema: (formValues) => {
    const resolvedRefsSchema = get().resolvedRefsSchema;
    const schemaData = {
      ...resolvedRefsSchema?.properties.SubmissionForm,
      definitions: resolvedRefsSchema?.definitions,
    };

    const clonedSchema = cloneDeep(schemaData);
    const oldResolvedSchemas = get().resolvedSchemas;
    const resolvedSchemas = resolveSchemas(clonedSchema, formValues);

    if (isEqual(oldResolvedSchemas, resolvedSchemas)) {
      return;
    }

    set({ resolvedSchema: resolvedSchemas[0], resolvedSchemas });
  },
  resetSchemaStore: () =>
    set({
      isValid: false,
      resolvedRefsSchema: null,
      errors: null,
      dataItems: null,
      schemaTree: null,
      schemaTreeList: null,
      schemaTreeListWithParents: null,
      schemaValidation: {},
    }),
  updateSchemaStore: (dataItems = {}) => {
    const resolvedRefsSchema = cloneDeep(schemaCDR) as unknown as JSONSchema;
    resolveRefs(resolvedRefsSchema);

    const isTemplate = isTemplatePath();
    const schemaTree = resolveSchemaTreeFromForm(schemaCDR, dataItems, isTemplate);
    const schemaTreeWithConditionalFields = isTemplate
      ? schemaTree
      : resolveSchemaTreeFromForm(schemaCDR, dataItems, true);

    const schemaTreeList = flattenResolvedSchemaTree(schemaTree);

    const schemaTreeListWithConditionalFields = flattenResolvedSchemaTree(schemaTreeWithConditionalFields);
    const schemaTreeListWithParents = schemaTreeToFlatList(schemaTree, true);

    set({
      schemaTree,
      schemaTreeWithConditionalFields,
      schemaTreeList,
      schemaTreeListWithParents,
      dataItems,
      resolvedRefsSchema,
      schemaTreeListWithConditionalFields,
    });
  },

  getSectionValidationState: (id: string) => {
    return (
      get().schemaValidation[id] ?? {
        hasErrors: false,
        hasValues: false,
        errors: [],
      }
    );
  },
  validate: () => {
    const formValues = useDatapointsStore.getState().formValues;
    const schemaTreeListWithParents = get().schemaTreeListWithParents;

    const isValid = compiledSchema(formValues);
    const errors = compiledSchema.errors as unknown as FormError[] | null;
    set({
      //
      schemaValidation: createSchemaValidation(schemaTreeListWithParents, formValues, errors),
      errors,
      isValid,
    });
  },
  findField: (id: string, shouldIncludeParentNodes = false, shouldIncludeConditionalFields = false) => {
    const schemaTree = shouldIncludeConditionalFields ? get().schemaTreeWithConditionalFields : get().schemaTree;

    if (!schemaTree) {
      return null;
    }

    const schemaTreeList = schemaTreeToFlatList(schemaTree, shouldIncludeParentNodes);
    return schemaTreeList.find((item: SchemaTreeToFlatListResult) => item.id === id);
  },
}));
