import React from 'react';
import { useQuery } from 'react-query';
import { getUsersTableColumns } from '@pages/User/helpers/columns/getUsersTableColumns';
import type { TransformedCognitoUser } from '@root/@types/cognito';
import type { Action } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchUsers } from '@src/queries';

import { MODALS } from '../../constants';

import { ReactTable } from './components';
import { useDataIsEmpty } from './hooks';

export const Users: React.FC = () => {
  const { showModal } = useModal();

  const { data = [], status } = useQuery(['users'], fetchUsers, { refetchOnWindowFocus: false });
  const isEmpty = useDataIsEmpty(data, status);

  const handleResendButtonClick = (user: TransformedCognitoUser) => () =>
    showModal(MODALS.ADMIN_USER_RESEND_INVITE, { user });

  const handleEditUserClick = (user: TransformedCognitoUser) => () =>
    showModal(MODALS.ADMIN_USER, { user, isAdd: false });

  const actions: Action[] = [
    {
      key: 'create',
      label: 'New User',
      onClick: () => {
        showModal(MODALS.ADMIN_USER, { isAdd: true });
      },
    },
  ];

  const columns = getUsersTableColumns({ handleEditUserClick, handleResendButtonClick });

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
      }}
      entityName="user"
      filteredData={data}
      initialSorting={[{ id: 'created_at', desc: true }]}
      status={status}
      isEmpty={isEmpty}
    />
  );
};

export const UserUsersPage = withMenuLayout(Users);
