import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { cloneDeep } from 'lodash-es';

import schema from '../../helpers/schema/schema-cdr.json';

import { ensureSubDivisionBelongsToCountry, uiComponent } from './keywords';

export const prepareSchemaForValidation = (schema: any) => {
  return {
    ...schema.properties.SubmissionForm,
    definitions: schema.definitions,
  };
};

const ajv = new Ajv({
  strict: false,
  allErrors: true,
  verbose: true,
  coerceTypes: true,
  useDefaults: true,
});

export const addCustomKeywords = (ajvInstance: Ajv) => {
  ajvInstance.addKeyword(uiComponent);
  ajvInstance.addKeyword(ensureSubDivisionBelongsToCountry);
  ajvInstance.addKeyword({ keyword: 'cdrId', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'cdrName', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'displayTitleForYesAnswer', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'formatting', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'hasHeading', schemaType: 'boolean' });
  ajvInstance.addKeyword({ keyword: 'helperText', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'isIf', schemaType: 'boolean' });
  ajvInstance.addKeyword({ keyword: 'placeholderText', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'qid', schemaType: 'number' });
  ajvInstance.addKeyword({ keyword: 'section_id', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'shortName', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'thousandSeparator', schemaType: 'boolean' });
  ajvInstance.addKeyword({ keyword: 'ui:className', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'ui:collapsible', schemaType: 'boolean' });
  ajvInstance.addKeyword({ keyword: 'ui:columns', schemaType: 'number' });
  ajvInstance.addKeyword({ keyword: 'ui:hideTitle', schemaType: 'boolean' });
  ajvInstance.addKeyword({ keyword: 'ui:placeholder', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'ui:span', schemaType: ['string', 'number'] });
  ajvInstance.addKeyword({ keyword: 'ui:startCollapsed', schemaType: 'boolean' });
  ajvInstance.addKeyword({ keyword: 'ui:toggleDependsOn', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'unit', schemaType: 'string' });
  ajvInstance.addKeyword({ keyword: 'wsId', schemaType: 'string' });

  addFormats(ajvInstance);
};

addCustomKeywords(ajv);

export const compiledSchema = ajv.compile(prepareSchemaForValidation(cloneDeep(schema)));

export default ajv;
