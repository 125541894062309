import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import { get } from 'lodash-es';

import type { InputMultiSelectProps } from '../InputMultiSelect/InputMultiSelect';
import InputMultiSelect from '../InputMultiSelect/InputMultiSelect';

export interface InputMultiSelectSchemaProps extends InputMultiSelectProps {
  item: {
    helperText?: string;
    shortName?: string;
    items: {
      type: string;
      enum: string[];
    };
  };
  ownKey: string;
}

const InputMultiSelectSchema: React.FC<InputMultiSelectSchemaProps> = ({ item, ownKey, ...props }) => {
  const { errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);

  const shouldHideTitle = get(item, 'ui:hideTitle', false);

  return (
    <InputMultiSelect
      value={value === '' ? [] : value}
      {...props}
      options={item?.items?.enum}
      isIncomplete={isIncomplete}
      helperText={item?.helperText}
      shortName={item?.shortName}
      shouldHideTitle={shouldHideTitle}
    />
  );
};

export default InputMultiSelectSchema;
