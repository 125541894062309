import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { EntityField } from '@ContractBuilder/contract-builder-header/constants';
import type { EntityData } from '@ContractBuilder/types';
import { getPdfBrandingList } from '@pdf/branding/config-default';
import type { BrandingType, TemplateShort, UISelectionType } from '@root/@types/types';
import { getFeatureFlag } from '@root/helpers/feature-flags';
import { fetchBrandings } from '@src/queries';

type OnCopyBrandingFromResource = (resource?: TemplateShort | EntityData) => string | undefined;

interface UseBrandingOptionsReturn {
  brandingFieldName: EntityField.Branding | EntityField.BrandingId;
  data: UISelectionType[] | string[];
  isLoading: boolean;
  onCopyBrandingFromResource: OnCopyBrandingFromResource;
}
type UseBrandingOptionsHook = () => UseBrandingOptionsReturn;

const useDynamicBrandingOptions: UseBrandingOptionsHook = () => {
  const { data, isFetching } = useQuery<BrandingType[]>(['brandings'], () => fetchBrandings(), {
    refetchOnWindowFocus: false,
  });

  const onCopyBrandingFromResource: OnCopyBrandingFromResource = useCallback(
    (resource) => {
      if (!resource) {
        return undefined;
      }

      const branding = data?.find((item) => item.id === resource.branding_id);

      if (!branding || branding.status !== 'PUBLISHED') {
        return undefined;
      }

      return branding.id;
    },
    [data],
  );

  return {
    brandingFieldName: EntityField.BrandingId,
    data: data?.filter(({ status }) => status === 'PUBLISHED').map(({ id, name }) => ({ name, value: id })) ?? [],
    isLoading: isFetching,
    onCopyBrandingFromResource,
  };
};

const usePredefinedBrandingOptions: UseBrandingOptionsHook = () => {
  const data = getPdfBrandingList();

  const onCopyBrandingFromResource: OnCopyBrandingFromResource = useCallback(
    (resource) => {
      if (!resource) {
        return undefined;
      }

      return data.find((item) => item === resource.branding);
    },
    [data],
  );

  return {
    brandingFieldName: EntityField.Branding,
    data,
    isLoading: false,
    onCopyBrandingFromResource,
  };
};

const getBrandingOptionsHook = () =>
  getFeatureFlag('Branding') ? useDynamicBrandingOptions : usePredefinedBrandingOptions;

export const useBrandingOptions = getBrandingOptionsHook();
