import { type FC, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '@components/Button';
import Modal from '@components/Modal';
import Nav from '@src/routes';

import { useBrandingStore } from '../../store/branding.store';
import { useBrandingTemplateStore } from '../branding-template-selector/branding-templates.store';

import { CreateBrandingForm } from './CreateBrandingForm';

export interface CreateBrandingModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateBrandingModal: FC<CreateBrandingModalProps> = ({ handleClose: onClose }) => {
  const navigate = useNavigate();
  const { form, branding, duplicateBranding, reset } = useBrandingStore((s) => s);
  const { selectedBrandingId } = useBrandingTemplateStore((s) => s);
  const isValid = useMemo(() => form.isDirty && branding, [form, branding]);

  const handleClose = () => {
    reset();
    return onClose();
  };

  const handleCreate = async () => {
    const result = await duplicateBranding(selectedBrandingId);

    if (result.success) {
      handleClose();
      const nextRoute = generatePath(Nav.BrandingDetails, { id: result.id });
      return navigate(nextRoute);
    } else {
      toast.error(result.error || 'There was an error creating your branding. Please try again.');
    }
  };

  return (
    <Modal
      className="w-full max-w-[512px]"
      onClose={handleClose}
      open
      shouldShowCloseIcon={false}
      title="Create a new branding"
      titleClassName="text-lg leading-6 font-medium !justify-center"
    >
      <div className="flex w-full flex-col items-center justify-center gap-6 px-8 pb-6 pt-0">
        <p className="text-sm font-normal leading-5 text-gray-500">Setup a new header & footer branding</p>
        <CreateBrandingForm />
        <div className="inline-flex items-start justify-start gap-3 self-stretch">
          <div className="grid w-full grid-cols-2 justify-between gap-3">
            <Button kind="secondary" className="h-9 min-w-[80px]" onClick={handleClose}>
              Cancel
            </Button>
            <Button kind="primary" className="h-9 min-w-[80px]" isDisabled={!isValid} onClick={handleCreate}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
