// source:
// https://github.com/vytenisu/use-history-back-trap/blob/main/lib/use-history-back-trap.ts
// + check if window is defined
import React from 'react';
import { useEffectOnce } from '@hooks/useEffectOnce';

const BACK_EVENT_TYPE = 'popstate';
const DEFAULT_TRAP_FLAG = 'backTrap';
const DEFAULT_TRAP_TIME = 'backTime';

export type HistoryBackTrapHandler = (resume: () => Promise<void>) => Promise<boolean>;

export interface HistoryBackTrapOptions {
  trapFlag: string;
  trapTime: string;
}

export const useHistoryBackTrap = (trapHandler: HistoryBackTrapHandler, options?: HistoryBackTrapOptions) => {
  const isBrowser = typeof window !== 'undefined';

  const expectedTimeRef = React.useRef();

  const trapFlag = options?.trapFlag ?? DEFAULT_TRAP_FLAG;
  const trapTime = options?.trapTime ?? DEFAULT_TRAP_TIME;

  const getStepTimestamp = () => window.history.state?.[trapTime];
  const isInsideTrap = () => Boolean(window.history.state?.[trapFlag]);

  const injectTrap = () => {
    const timestamp = new Date().getTime();
    window.history.replaceState({ [trapFlag]: true, [trapTime]: timestamp }, '', window.location.href);
    window.history.pushState({ [trapTime]: timestamp }, '', window.location.href);
  };

  const resume = async () => {
    window.history.back();
  };

  useEffectOnce(() => {
    if (!isBrowser) return;
    injectTrap();
    expectedTimeRef.current = getStepTimestamp();
  });

  React.useEffect(() => {
    if (!isBrowser) return;
    const isCorrectTimestamp = (timestamp: number) =>
      timestamp && expectedTimeRef.current && expectedTimeRef.current === timestamp;

    const trap = async () => {
      if (isInsideTrap()) {
        if (!isCorrectTimestamp(getStepTimestamp())) {
          resume();
          return;
        }

        const approved = await trapHandler(resume);

        if (!approved) {
          injectTrap();
          expectedTimeRef.current = getStepTimestamp();
        }
      }
    };

    window?.addEventListener(BACK_EVENT_TYPE, trap as any);

    return () => {
      window?.removeEventListener(BACK_EVENT_TYPE, trap as any);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trapHandler]);
};
