import type { EntityData } from '@ContractBuilder/types';
import { DocumentContext } from '@ContractBuilder/utils/get-document-context';
import { canChange } from '@helpers/canChange';
import type { BlockDetails } from '@hooks/use-block-flags-generator';
import type { ResourceBlock } from '@root/@types/base';
import type { BaseUser } from '@root/@types/types';
import { isSummaryBlock } from '@root/helpers/blocks';
import { get, isMatch } from 'lodash-es';

import type { Rules } from '../types';

import { crossCheckBlockUserCtx } from './cross-check-block-user-ctx';
import { ctxIsPDF, ctxIsTemplate } from './is-ctx';

const VISIBILITY_APPLICABLE_CTX = [DocumentContext.Submission, DocumentContext.PDF, DocumentContext.Endorsement];

export type BlockRulesArgs = {
  block: ResourceBlock;
  context: DocumentContext;
  disableControls: boolean;
  hasBeenMovedByRenewal: boolean;
  isDeletable: boolean;
  isEditable: boolean;
  isEditing: boolean;
  isEndorsementView: boolean;
  status: EntityData['status'];
  user: BaseUser | null;
};

export const blockRulesIsCreate = (block: ResourceBlock) => block?.id === '';
export const blockRulesIsVisible = (block: ResourceBlock, ctx: DocumentContext) =>
  !VISIBILITY_APPLICABLE_CTX.includes(ctx) ? true : block.isVisible;

export const getVariationsCount = (block: ResourceBlock) => get(block, 'variations.length', 0);

export type BlockRuleSet = Omit<
  BlockDetails,
  'variations' | 'visibilityDetails' | 'isEditable' | 'isEditing' | 'isDeletable' | 'hasBeenMovedByRenewal'
>;

export const blockRulesCommon: Rules<BlockRulesArgs, keyof BlockRuleSet> = {
  /**
   * Does block have auto-select logic for variations?
   */
  hasVariationsConditionalLogic: ({ block }) => !!get(block, 'visibility.variations.length', 0),
  /**
   * Block is in preview mode: controls disabled by props (e.g. library preview) or in PDF context
   */
  isPreview: ({ disableControls, context }) => disableControls || ctxIsPDF(context),
  /**
   * Should render block creator trigger?
   */
  showCreator: ({ block, disableControls, context, isEndorsementView }) => {
    if (ctxIsPDF(context)) {
      return false;
    }

    if (isEndorsementView && !isSummaryBlock(block)) {
      return false;
    }

    return !disableControls;
  },
  /**
   * Show icon: page break
   */
  showPageBreakIndicator: ({ block, context }) => block.shouldInsertPageBreak && blockRulesIsVisible(block, context),
  /**
   * Show icon: visibility reasons
   */
  showVisibilityReasonsIndicatorIcon: ({ block, isEndorsementView }) =>
    !isEndorsementView && !!block.visibilityComputed?.block?.info,
  /**
   * Show icon: variations
   */
  showVariationsIndicator: ({ block, context }) => !!getVariationsCount(block) && blockRulesIsVisible(block, context),
  /**
   * Show icon: used in templates
   */
  showUsedInTemplatesControls: ({ context }) => ctxIsTemplate(context),
  /**
   * Show icon: used in templates
   */
  showDataExtractionIndicator: ({ block }) => block.extractionCandidateId !== undefined,
  /**
   * Show icon: block is locked
   */
  showLockedIndicator: ({ block, context, user }) => {
    const { canBeDeletedWithCurrentPermissions, canBeEditedWithCurrentPermissions } = crossCheckBlockUserCtx(
      block,
      context,
      user,
    );

    return (
      !canBeDeletedWithCurrentPermissions ||
      !canBeEditedWithCurrentPermissions ||
      (!ctxIsTemplate(context) && (!block.canEdit || !block.canDelete))
    );
  },
  /**
   * Show block alerts icon and info.
   */
  showBlockAlerts: ({ context, block, isEndorsementView, hasBeenMovedByRenewal }) => {
    if (hasBeenMovedByRenewal) {
      return true;
    }

    const isPDFOrTemplate = [DocumentContext.PDF, DocumentContext.Template].includes(context);
    return !isPDFOrTemplate && blockRulesIsVisible(block, context) && !isEndorsementView;
  },
  /**
   * Show block actions.
   */
  showBlockActions: ({ block, context, disableControls }) =>
    blockRulesIsVisible(block, context) && !disableControls && !ctxIsPDF(context),
  /**
   * Used for alerts if variation is not selected in block.
   */
  isVariationActionRequired: ({ context, isEditing, block }) =>
    !ctxIsPDF(context) && ctxIsTemplate(context) && !isEditing && !!getVariationsCount(block),
  /**
   * Should render page break. Mainly in PDF context.
   */
  renderPageBreak: ({ block, context }) => block.shouldInsertPageBreak && ctxIsPDF(context),
  /**
   * Is block visible in current context
   */
  isVisible: ({ block, context }) => blockRulesIsVisible(block, context),
  /**
   * Should render `Permissions Set Indicator` when block permissions differ from the defaults.
   */
  showPermissionsSetIndicator: ({ block, context }) => {
    const permissionDefaults = { canDelete: true, canDeleteOnTemplate: true, canEdit: true, canEditOnTemplate: true };
    return ctxIsTemplate(context) && !isMatch(block, permissionDefaults);
  },
  isSummaryBlock: ({ block, isEndorsementView }) => Boolean(isSummaryBlock(block) && isEndorsementView),
  isParentEntityEditable: ({ status }) => canChange(status),
};

export { blockRulesIsDeletable } from './deletable';
export { blockRulesHasBeenMovedByRenewal } from './moved-by-renewal';
