import React, { Suspense, useCallback, useState } from 'react';
import { BlockVisibilityWarningsModal } from '@ContractBuilder/components/Modal/BlockVisibilityWarnings';
import ConfirmDeleteModal from '@ContractBuilder/components/Modal/ConfirmDeleteModal';
import ContactSupportModal from '@ContractBuilder/components/Modal/ContactSupportModal';
import { EndorsementModal } from '@ContractBuilder/components/Modal/EndorsementModal';
import AdminOpenPdfModal from '@ContractBuilder/components/Modal/PdfModal';
import { AttachmentRenameModal } from '@ContractBuilder/modules/attachment/components/AttachmentRenameModal';
import { AttachmentUploadModal } from '@ContractBuilder/modules/attachment/components/AttachmentUploadModal';
import { BlockVisibilityModal } from '@ContractBuilder/modules/block-visibility-modal';
import { RestoreRevisionModal } from '@ContractBuilder/modules/revision-history';
import { CreateEntityModalController } from '@features/create-entity-modal';
import { CreateRenewalModalController } from '@features/create-renewal-modal';
import { ReplaceBaseTemplateModalController } from '@features/replace-base-template-modal';
import { ConfirmStatusModal } from '@root/src/containers/ContractBuilder/components/Modal/ConfirmStatusModal';
import TagModal from '@User/Tags/modals/TagModal';
import UserModal from '@User/Users/modals/UserModal';
import UserResendInviteModal from '@User/Users/modals/UserResendInviteModal';

import { MODALS } from '../constants';
import { CreateBrandingModal } from '../features/branding-edit/components/create-branding/CreateBrandingModal';
import { ImageUploadController } from '../features/branding-edit/components/image-upload/ImageUploadController';
import ConfirmBrandingStatusModal from '../features/branding-edit/modals/ConfirmBrandingStatusModal';

import type { ModalContextState } from './useModal';
import { ModalContext, useModal } from './useModal';

const modalsMap = new Map();
modalsMap.set(MODALS.ADMIN_CREATE_TEMPLATE, CreateEntityModalController);
modalsMap.set(MODALS.ATTACHMENT_UPLOAD, AttachmentUploadModal);
modalsMap.set(MODALS.ATTACHMENT_RENAME, AttachmentRenameModal);
modalsMap.set(MODALS.ADMIN_OPEN_PDF_MODAL, AdminOpenPdfModal);
modalsMap.set(MODALS.ADMIN_TAG, TagModal);
modalsMap.set(MODALS.ADMIN_USER, UserModal);
modalsMap.set(MODALS.ADMIN_USER_RESEND_INVITE, UserResendInviteModal);
modalsMap.set(MODALS.CREATE_ENDORSEMENT, EndorsementModal);
modalsMap.set(MODALS.CONFIRM_DELETE, ConfirmDeleteModal);
modalsMap.set(MODALS.CONFIRM_STATUS_FINAL, ConfirmStatusModal);
modalsMap.set(MODALS.CONFIRM_STATUS_FINAL_BRANDING, ConfirmBrandingStatusModal);
modalsMap.set(MODALS.RESTORE_REVISION, RestoreRevisionModal);
modalsMap.set(MODALS.BLOCK_VISIBILITY_MODAL, BlockVisibilityModal);
modalsMap.set(MODALS.BLOCK_VISIBILITY_WARNING_MODAL, BlockVisibilityWarningsModal);
modalsMap.set(MODALS.UPLOAD_IMAGE, ImageUploadController);
modalsMap.set(MODALS.CREATE_BRANDING, CreateBrandingModal);
modalsMap.set(MODALS.CONTACT_SUPPORT, ContactSupportModal);
modalsMap.set(MODALS.CREATE_RENEWAL, CreateRenewalModalController);
modalsMap.set(MODALS.REPLACE_BASE_TEMPLATE, ReplaceBaseTemplateModalController);

export const ModalContextProvider = ({ children }: { children: any }) => {
  const [modal, setVisibleModal] = useState<ModalContextState>(undefined);
  const [props, setProps] = useState(null);

  const showModal = useCallback(
    (modalName: React.SetStateAction<ModalContextState>, nextProps: React.SetStateAction<null>) => {
      setVisibleModal(modalName);
      setProps(nextProps);
    },
    [],
  );

  const closeModal = useCallback(() => setVisibleModal(undefined), []);

  return <ModalContext.Provider value={{ modal, showModal, closeModal, props }}>{children}</ModalContext.Provider>;
};

export const ModalSwitch = () => {
  const { modal, closeModal, props } = useModal();
  const Component = modalsMap.get(modal);

  if (!Component) {
    return null;
  }

  return (
    <Suspense>
      <Component handleClose={closeModal} {...props} />
    </Suspense>
  );
};
