import type { ChangeEvent } from 'react';
import React from 'react';
import Button from '@components/Button';
import InputText from '@components/InputText';
import Modal from '@components/Modal';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useBlockLibrary } from '@hooks/useBlockLibrary';
import clsx from 'clsx';

export const SaveAsNewChildBlockModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { formValues, currentBlock, setFormValues } = useBlockEditFormStore(
    ({ formValues, currentBlock, setFormValues }) => ({
      formValues,
      currentBlock,
      setFormValues,
    }),
  );

  const { data: parentBlock, isFetching } = useBlockLibrary(
    currentBlock?.parentBlockId ?? '',
    currentBlock?.parentBlockId !== undefined && isOpen,
  );

  if (
    currentBlock === undefined ||
    (currentBlock?.parentBlockId !== undefined && parentBlock === undefined) ||
    isFetching
  ) {
    return null;
  }

  const handleChangeChildBlockName = (event: ChangeEvent<HTMLInputElement>) =>
    setFormValues((prev) => ({ ...prev, name: event.target.value }));

  return (
    <Modal
      title="Save as new child block"
      description="This will create a new child version of the original parent block, grouped together in the block library"
      onClose={onClose}
      open={isOpen}
      className={clsx('max-w-[470px] p-10 sm:p-10', MODAL_Z_INDEX)}
      modalClassName="px-6"
      titleClassName="px-6 !block"
      descriptionClassName="text-center p-2"
      data-testid="save-as-child-block-modal"
      shouldShowCloseIcon={false}
    >
      <div className="flex items-center py-6">
        <ChevronRightIcon aria-hidden="true" className="size-5 h-4 w-4 shrink-0 text-gray-400" />
        <span className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700">
          {parentBlock?.name ?? currentBlock.name}
        </span>
      </div>
      <div className="flex items-center pb-2 pt-4">
        <InputText labelText="Name child block" onChange={handleChangeChildBlockName} value={formValues?.name ?? ''} />
      </div>
      <div className="flex items-center justify-between pt-8">
        <Button size="sm" kind="secondary" className="mr-4 h-9 w-full" onClick={onClose}>
          Cancel
        </Button>
        <Button size="sm" kind="primary" loading={false} className="h-9 w-full" isDisabled={false} onClick={onConfirm}>
          Create
        </Button>
      </div>
    </Modal>
  );
};
