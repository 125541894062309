import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '@Auth/components/LoadingSpinner';
import { useUserStore } from '@Auth/store';
import { authProvider } from '@helpers/auth/authProvider';
import { useEffectOnce } from '@src/hooks';
import Nav from '@src/routes';

export const CognitoLogout = () => {
  const navigate = useNavigate();

  const signOut = async () => {
    try {
      useUserStore.getState().clearUser();
      await authProvider.logout();
    } finally {
      navigate(Nav.Login);
    }
  };

  useEffectOnce(() => {
    signOut();
  });

  return <LoadingSpinner />;
};
