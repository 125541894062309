import { type FC } from 'react';
import Icon from '@components/Icon';
import { Show } from '@components/Show';
import { isBlockValid } from '@ContractBuilder/utils/block-validation';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import type { ResourceBlock } from '@root/@types/base';
import { BlockError } from '@root/@types/types';
import { BLOCK_ACTIONS_Z_INDEX } from '@root/src/constants/z-indices';
import clsx from 'clsx';

import Tooltip from '../../../../../components/Tooltip';

const messages = {
  [BlockError.BlockEmpty]: 'Block is empty',
  [BlockError.DatapointEmpty]: 'Block has incomplete datapoints',
  [BlockError.VariationNotSelected]: 'Select a variation of this block',
};

interface BlockAlertProps {
  block: ResourceBlock;
  hasBeenMovedByRenewal: boolean;
}

const wrapperClasses = [
  'hover:cursor-pointer',
  'transition-all',
  'duration-200',
  'ease-in-out',
  'text-xs',
  'rounded-full',
  'p-0.25',
  'bg-white',
  'shadow-xl',
];

export const BlockAlert: FC<BlockAlertProps> = ({ block, hasBeenMovedByRenewal }) => {
  const isValid = useDeepCompareMemo(() => isBlockValid(block), [block]);

  if (isValid && !hasBeenMovedByRenewal) {
    return null;
  }

  return (
    <div
      className={clsx('absolute left-0 top-1 flex -translate-x-1/2 transform flex-col gap-1', BLOCK_ACTIONS_Z_INDEX)}
    >
      <Show when={!isValid}>
        <Tooltip
          content={
            <ul className="text-center">
              {block.validationErrors?.map((problem) => <li key={problem}>{messages[problem]}</li>)}
            </ul>
          }
        >
          <div className={clsx(wrapperClasses)}>
            <Icon className="h-7 w-7" name="alert-circle" />
          </div>
        </Tooltip>
      </Show>
      <Show when={hasBeenMovedByRenewal}>
        <Tooltip content="Block moved during renewal, please check position">
          <div className={clsx(wrapperClasses, 'flex items-center justify-center')}>
            <Icon className="h-7 w-7" name="move" />
          </div>
        </Tooltip>
      </Show>
    </div>
  );
};
