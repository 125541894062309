import { SECTION_TITLE_KEY } from '@root/helpers/schema/constants';
import { isMultiSelect } from '@root/helpers/schema/is-multi-select';
import type { SchemaObject } from 'json-schema-traverse';

import { resolveSchema } from './resolveSchema';
import type { ResolvedSchemaField } from './types';

const getPropertiesAndItems = (
  schema: SchemaObject,
  path: string,
  section: number = 0,
  forceConditionalFields: boolean = true,
): [string, ResolvedSchemaField][] => {
  if (schema.properties)
    return Object.entries(schema.properties).map(([key, value]) => [`${path ? path + '.' : ''}${key}`, value]) as [
      string,
      ResolvedSchemaField,
    ][];

  if (schema.items && !isMultiSelect(schema['ui:component'])) {
    const resolvedItems = resolveSchema(schema.items, {}, forceConditionalFields);
    return Object.entries(resolvedItems.properties).map(([key, value]) => [
      `${path ? path + '.' : ''}${section}.${key}`,
      value,
    ]) as [string, ResolvedSchemaField][];
  }

  return [];
};

const expandChild = (schema: any, path: string): ResolvedSchemaField[] => {
  const shouldSkip = !schema.title || /items\.\d$/.test(path);

  schema.id = path;
  if (!shouldSkip) return [schema];

  return Object.entries(schema.properties)
    .map(([key, value]) => expandChild(value, `${path ? path + '.' : ''}${key}`))
    .flat();
};

export const getChildrenWithPath = (
  schema: SchemaObject,
  path: string,
  section: number = 0,
  forceConditionalFields: boolean = true,
): ResolvedSchemaField[] => {
  return getPropertiesAndItems(schema, path, section, forceConditionalFields)
    .map(([path, value]) => expandChild(value, path))
    .flat()
    .filter((item) => !item.id.endsWith('_other') && !item.id.endsWith(SECTION_TITLE_KEY));
};
