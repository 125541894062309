import type { FC } from 'react';
import { useQuery } from 'react-query';
import { useEntityStore } from '@ContractBuilder/store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { EntityData } from '@ContractBuilder/types';
import { isEndorsementData } from '@ContractBuilder/types';
import { getDocumentContext } from '@ContractBuilder/utils';
import type { Branding } from '@domain/schemas/branding.schema';
import { useBrandingStore } from '@features/branding-edit/store/branding.store';
import { downloadDocument } from '@helpers/downloadDocument';
import { usePullPdfDocumentUrl } from '@hooks/pullDocumentUrl';
import { fetchEntity } from '@queries/fetchEntity';
import { getDataExtractionResult } from '@queries/getDataExtractionResult';
import { first } from 'lodash-es';

import { getResourceNameAndDocumentType } from '../utils/getResourceNameAndDocumentType';
import { usePrefetchPDFs } from '../utils/usePrefetchPDFs';
import { PDFPreview } from '../views/PDFPreview';

interface PDFPreviewControllerProps {
  focussedBlockId?: string;
  focussedCandidate?: string;
}

export const PDFPreviewController: FC<PDFPreviewControllerProps> = ({ focussedBlockId, focussedCandidate }) => {
  const { submission } = useEntityStore(({ submission }) => ({ submission }));

  const { data: freshSubmission } = useQuery(
    [submission?.pk, submission?.sk, submission?.updated_at],
    () => fetchEntity({ dataRange: ['data_extractions'] }),
    {
      refetchOnMount: 'always',
      enabled: !!submission?.pk && !!submission?.sk,
    },
  );
  const branding = useBrandingStore(({ branding }) => branding);
  const togglePreview = useUIStore(({ togglePDFPreview }) => togglePDFPreview);

  let entity: EntityData | Partial<Branding> | null | undefined = undefined;
  let documentName = '';

  const documentContext = getDocumentContext();
  const { documentType, resourceName } = getResourceNameAndDocumentType(documentContext);

  switch (documentType) {
    case 'branding-document':
      entity = branding;
      documentName = 'PDF Preview';
      break;
    default:
      entity = freshSubmission;
      documentName = (entity && isEndorsementData(entity) ? entity.reference : entity?.name) ?? 'PDF Document';
      break;
  }

  const {
    documentVersion,
    expectedVersion,
    refetch: refetchDocumentUrl,
    url,
    documentPullUpdatedAt,
  } = usePullPdfDocumentUrl(resourceName, documentType, entity?.version ?? 1);

  const dataExtractionItem = entity && 'dataExtractions' in entity ? first(entity?.dataExtractions) : undefined;

  const { data, refetch } = useQuery(
    ['get-data-extraction-result', dataExtractionItem?.id],
    () => getDataExtractionResult(entity?.id as string, dataExtractionItem?.id as string),
    {
      enabled: dataExtractionItem?.id !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  usePrefetchPDFs({ documentPullUpdatedAt, documentUrl: url, extractionFileUrl: data?.fileUrl });

  const handleExtractionDocumentDownload = async () => {
    const response = await refetch();

    if (response.data?.fileUrl) {
      downloadDocument(response.data?.fileUrl);
    }
  };

  const handleSubmissionDocumentDownload = async () => {
    const response = await refetchDocumentUrl();

    if (response.data?.data.url) {
      downloadDocument(response.data?.data.url);
    }
  };

  if (documentType === 'branding-document') {
    documentName = 'PDF Preview';
  }

  return (
    <PDFPreview
      dataExtractionItem={dataExtractionItem}
      documentName={documentName}
      documentUrl={url}
      documentVersion={documentVersion}
      expectedVersion={expectedVersion}
      extractionFileUrl={data?.fileUrl}
      focussedBlockId={focussedBlockId}
      focussedCandidate={focussedCandidate}
      onClose={togglePreview}
      onDownloadExtractionDocument={handleExtractionDocumentDownload}
      onDownloadSubmissionDocument={handleSubmissionDocumentDownload}
    />
  );
};
