import type { FC } from 'react';
import type { ButtonProps } from '@components/Button';
import Button from '@components/Button';
import { Show, ShowFirstMatching } from '@components/Show';
import { isEmptyValue } from '@root/helpers';

import type { CreateRenewalFormState, StepProps } from '../types';
import { Step } from '../types';

interface FormFooterProps extends StepProps {
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

const getIsButtonDisabled = (formValues: CreateRenewalFormState, step: Step, isLoading: boolean) => {
  const isFirstStep = step === Step.Base;
  const isThirdStep = step === Step.Config;

  const hasName = !isEmptyValue(formValues.name);
  const hasSelectedTemplate = !isEmptyValue(formValues.baseTemplateId);

  if (isLoading) {
    return true;
  }

  if (isFirstStep) {
    return !hasName;
  }

  if (isThirdStep) {
    return !hasSelectedTemplate;
  }

  return false;
};

export const FormFooter: FC<FormFooterProps> = ({ formValues, isLoading, onClose, onNext, onPrevious, step }) => {
  const isFirstStep = step === Step.Base;
  const isSecondStep = step === Step.Type;
  const isReplicate = formValues.type === 'replicate';
  const handleClose = () => (isFirstStep ? onClose() : onPrevious());

  const buttonProps: ButtonProps = {
    className: 'h-[2.375rem] w-full',
    isDisabled: getIsButtonDisabled(formValues, step, isLoading),
    kind: 'primary',
    loading: isLoading,
  };

  return (
    <div className="mt-4 flex w-full justify-between gap-4">
      <Button isDisabled={isLoading} onClick={handleClose} className="h-[2.375rem] w-full">
        {isFirstStep ? 'Cancel' : 'Back'}
      </Button>
      <ShowFirstMatching>
        <Show when={isFirstStep || (isSecondStep && !isReplicate)}>
          <Button {...buttonProps} onClick={onNext} type="button">
            Next
          </Button>
        </Show>
        <Show when={true}>
          <Button {...buttonProps} type="submit">
            Create
          </Button>
        </Show>
      </ShowFirstMatching>
    </div>
  );
};
