import type { PublishingOptionType } from '../types';

export const templatePublishingOptions: PublishingOptionType[] = [
  {
    id: 'DRAFT',
    title: 'Draft',
    description: 'This template is a work in progress and unavailable to use on new contracts.',
  },
  {
    id: 'PUBLISHED',
    title: 'Published',
    description: 'Make this template available to use when creating new contracts.',
  },
  {
    id: 'ARCHIVED',
    title: 'Archived',
    description: 'Prevent this template from being used when creating new contracts.',
  },
];

export const submissionPublishingOptions: PublishingOptionType[] = [
  {
    id: 'DRAFT',
    title: 'Draft',
    description: 'This contract is a work in progress and not complete.',
  },
  {
    id: 'FINAL',
    title: 'Final',
    description: 'This contract is complete. Further changes will be made as endorsements.',
  },
  {
    id: 'ARCHIVED',
    title: 'Archived',
    description: 'This contract is not being used.',
  },
];

export const endorsementPublishingOptions: PublishingOptionType[] = [
  {
    id: 'DRAFT',
    title: 'Draft',
    description: 'This endorsement is a work in progress and not complete.',
  },
  {
    id: 'FINAL',
    title: 'Final',
    description: 'This endorsement is complete.',
  },
  {
    id: 'ARCHIVED',
    title: 'Archived',
    description: 'This endorsement is not being used.',
  },
];

export enum ViewPath {
  Contract = 'contract',
  Form = 'form',
  Endorsements = 'endorsements',
  EndorsementView = 'endorsement-view',
}

export enum EntityField {
  Name = 'name',
  TemplateBase = 'sourceResourceId',
  Branding = 'branding',
  BrandingId = 'branding_id',
  ClassOfBusiness = 'class_of_business',
  Teams = 'teams',
}
