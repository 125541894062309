import { BlockError, EndorsementPremiumChange, StandardisedSectionName } from '@root/@types/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { z } from 'zod';

import { DbSubmissionBlockSchema } from './block.schema';
import type { PK } from './database.schema';
import { baseDatabaseSchema } from './database.schema';

export enum ResourceState {
  DRAFT = 'DRAFT',
  FINAL = 'FINAL',
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
}

export const DbContractSchema = z
  .object({
    name: z.string(),
    status: z.nativeEnum(ResourceState),
    version: z.number(),
    data_items: z.any(), // this is covered by AJV validation
    layout: z.array(z.string()),
    attachments_layout: z.array(z.string()).optional(),
    document_name: z.string().optional(), // deprecated, can we remove it?
    document_type: z.string().optional(), // why do we need this? we only have one type which is MRC
    document_version: z.number().optional(), // Isn't this duplicated inside of documents?
    documents: z
      .array(
        z
          .object({
            name: z.string(),
            subtype: z.string().optional(),
            type: z.enum(['template-document', 'submission-document', 'endorsement-document', 'endorsement-summary']),
            version: z.number(),
            path: z.string().optional(),
          })
          .strict(),
      )
      .optional(), // should this be an array? won't there always just be one document per resource type?
    sourceResourceId: z.string().uuid(),
    sourceResourceName: z.enum(['templates', 'submissions']),
    sourceResourceDisplayName: z.string().optional(),
    teams: z.array(z.union([z.string(), z.number()])).optional(), // number for PRO-clients and string for PPL
    tags: z.array(z.string()).optional(),
    branding: z.string().optional(),
    branding_id: z.string().uuid().optional(),
    class_of_business: z.enum(ALL_CLASSES_OF_BUSINESS).optional(),
    external_id: z.string().optional(), // used for external PRO-clients API
    transaction_status: z.enum(['pending', 'complete']).optional(),

    // to be removed
    ref: z.string(), // i'd consider removing this, raise with JP
    schema_id: z.string().optional(), // remove ASAP
    revision_id: z.string().optional(), // remove
    revision_name: z.string().optional(), // remove
    revision_created_at: z.string().optional(), // remove
    umr: z.string().optional(), // remove
    tenant_id: z.string().optional(), // remove
  })
  .merge(baseDatabaseSchema);

export const DbEndorsementSchema = DbContractSchema.extend({
  additional_premium: z.object({ amount: z.number(), currency: z.string() }).optional(),
  effective_date: z.string().datetime(), // should this be optional?
  expiry_date: z.string().datetime().optional(), // should this be optional?
  premium_change: z.nativeEnum(EndorsementPremiumChange).optional(), // should this be optional?
  reason: z.string().optional(), // should this be optional?
  reference: z.string().optional(), // should this be optional?
  summary_layout: z.array(z.string()).optional(),
  blocks_excluded_from_diff: z.array(z.string()).optional(),
  type: z.string().optional(), // this is an enum?
  summary: z.object({
    blocksExcludedFromPreview: z.array(z.string()).default([]),
    layout: z.array(z.string()).default([]),
  }),

  // to be removed
  endorsement_amendment_text: z.string().optional(), // deprecated?
  endorsement_summary: z.string().optional(), // deprecated?
  parentId: z.string(), // deprecated?
}).strict();

export const SectionSchema = z.object({
  label: z.string(),
  layout: z.array(z.string()).default([]),
  should_hide_title_in_pdf: z.boolean().optional(),
  should_reset_page_counter: z.boolean().optional(),
  standardised_name: z.nativeEnum(StandardisedSectionName).optional(),
  // to be removed
  parentSectionId: z.string().optional(),
});

export const DbSectionSchema = SectionSchema.merge(baseDatabaseSchema);

/*
 Response Schemas
*/
export const BlockResponseSchema = DbSubmissionBlockSchema.extend({
  content_transformed: z.string().optional(),
  validationErrors: z.array(z.nativeEnum(BlockError)).optional(),
  commentsCount: z.number().optional(),
});
export const SectionResponseSchema = DbSectionSchema.extend({
  blocks: z.array(BlockResponseSchema),
});
export const ContractResponseSchema = DbContractSchema.extend({
  sections: z.array(SectionResponseSchema),
});

export type DbContract = z.infer<typeof DbContractSchema> & PK;
export type DbTemplate = z.infer<typeof DbContractSchema> & PK;
export type DbSubmission = z.infer<typeof DbContractSchema> & PK;
export type DbEndorsement = z.infer<typeof DbEndorsementSchema> & PK;
export type DbSection = z.infer<typeof DbSectionSchema> & PK;
export type BlockResponse = z.infer<typeof BlockResponseSchema>;
export type SectionResponse = z.infer<typeof SectionResponseSchema>;
export type ContractResponse = z.infer<typeof ContractResponseSchema>;
