import { create } from 'zustand';

interface RenewalStoreSlice {
  /** Keep track of the block ids that have been moved in relation to the parent submission as a part of re-basing a renewal */
  movedBlockIds: string[];
  renewalId?: string;
  setMovedBlockIds: (blockIds: string[]) => void;
  setRenewalId: (renewalId: string) => void;
}

export const useRenewalStore = create<RenewalStoreSlice>((set) => ({
  movedBlockIds: [],
  renewalId: undefined,
  setMovedBlockIds: (blockIds) => set({ movedBlockIds: blockIds }),
  setRenewalId: (renewalId) => set({ renewalId: renewalId }),
}));
