import type { FC } from 'react';
import type { Action } from '@root/@types/types';

interface ActionButtonProps {
  action: Action;
}

export const ActionButton: FC<ActionButtonProps> = ({ action: { label, disabled, onClick } }) => (
  <div className="md:pl-0">
    <button
      disabled={disabled}
      aria-disabled={disabled}
      type="button"
      className="!xl-mr-auto inline-flex items-end justify-center rounded-md border border-transparent bg-primary-600 px-5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:w-auto"
      onClick={onClick}
    >
      {label}
    </button>
  </div>
);
