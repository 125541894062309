import { isPPLFrontend } from '@root/src/utils/app-paths';
import { cva } from 'class-variance-authority';

export const blockTitleClasses = cva(
  ['leading-normal', 'font-semibold', 'text-xs', 'break-words', 'uppercase', 'text-info-600'],
  {
    variants: {
      type: {
        clause: ['w-full'],
        generic: ['w-full'],
        'mrc-heading': ['w-mrc-block-title', 'mr-mrc-block-grid-cell', isPPLFrontend ? 'min-w-mrc-block-title' : ''],
      },
      pdf: {
        false: [],
        clause: [],
        generic: [],
        'mrc-heading': [],
      },
    },
  },
);

export const blockContentClasses = cva(['relative', 'block-content', 'w-full', 'flex-auto', 'min-w-0'], {
  variants: {
    type: {
      clause: ['clause-block'],
      generic: ['generic-block'],
      'mrc-heading': ['mrc-heading-block'],
    },
    pdf: {
      false: [],
      clause: [],
      generic: [],
      'mrc-heading': [],
    },
    disableMentions: {
      true: ['remove-interactive-mention'],
      false: [],
    },
  },
  defaultVariants: {
    disableMentions: false,
    pdf: false,
    type: 'mrc-heading',
  },
});
