import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { Show, ShowFirstMatching } from '@components/Show';
import { mdiCheck } from '@mdi/js';
import clsx from 'clsx';

import { stepperItemClasses } from './classes';
import type { StepperItemProps } from './types';

import './stepper.css';

const StepperItem: FC<StepperItemProps> = ({ isActive = false, isCompleted = false }) => {
  return (
    <div className={clsx(stepperItemClasses({ isActive, isCompleted }))}>
      <ShowFirstMatching>
        <Show when={isActive}>
          <div className="h-2.5 w-2.5 rounded-full bg-primary-600" />
        </Show>
        <Show when={isCompleted}>
          <IconMdi className="text-white" path={mdiCheck} />
        </Show>
      </ShowFirstMatching>
    </div>
  );
};

interface StepperProps {
  getStepperItemState: (step: number) => StepperItemProps;
  size?: number;
}

export const Stepper: FC<StepperProps> = ({ getStepperItemState, size = 3 }) => {
  const steps = new Array(size).fill(0);
  return (
    <div className="stepper-container relative flex justify-between text-gray-300" style={{ width: 72 * size }}>
      {steps.map((_, idx) => (
        <StepperItem {...getStepperItemState(idx + 1)} key={`stepper-item-${idx}`} />
      ))}
    </div>
  );
};
