import { useCallback, useState } from 'react';
import type { StepperItemProps } from '@components/Stepper';

import type { Step } from '../types';

interface UseStepperReturn {
  currentStep: Step;
  getStepperItemState: (step: Step) => StepperItemProps;
  onNext: () => void;
  onPrevious: () => void;
}

interface UseStepperArgs {
  onCancel: () => void;
}

export const useStepper = ({ onCancel }: UseStepperArgs): UseStepperReturn => {
  const [currentStep, setCurrentStep] = useState<Step>(1);

  const getStepperItemState = useCallback(
    (step: Step) => {
      const maxActiveStep = 3;

      if (step === Math.min(maxActiveStep, currentStep)) {
        return { isActive: true };
      }

      if (step < currentStep) {
        return { isCompleted: true };
      }

      return {};
    },
    [currentStep],
  );

  const onPrevious = useCallback(
    () =>
      setCurrentStep((current) => {
        if (current === 2) {
          return 1;
        }

        if (current === 3) {
          return 2;
        }

        if (current === 4) {
          return 3;
        }

        onCancel();
        return current;
      }),
    [onCancel],
  );

  const onNext = useCallback(
    () =>
      setCurrentStep((current) => {
        return Math.min(current + 1, 4) as Step;
      }),
    [],
  );

  return {
    currentStep,
    getStepperItemState,
    onNext,
    onPrevious,
  };
};
