import { useMemo } from 'react';
import { TooltipOnTruncatedCell } from '@components/TooltipOnTruncated';
import { type Cell, flexRender } from '@tanstack/react-table';
import { twMerge } from 'tailwind-merge';

import { responsiveCellClasses } from '../helpers/columns/shared/responsive-cols-classes';

const cellBaseClasses = 'w-42 truncate whitespace-nowrap px-3 py-4 align-top text-sm text-info-500';

interface TableCellProps<TData, TValue> {
  cell: Cell<TData, TValue>;
}

export const TableCell = <TData, TValue>({ cell }: TableCellProps<TData, TValue>) => {
  const { classes, tooltip } = useMemo(() => {
    const tooltip = cell.column.columnDef.meta?.tooltip;
    const classes = twMerge(
      cellBaseClasses,
      cell.column.columnDef?.meta?.dataCellClassName,
      responsiveCellClasses({
        breakpoint: cell.column.columnDef.meta?.showAboveBreakpoint,
      }),
    );

    return {
      tooltip,
      classes,
    };
  }, [cell]);

  const content = flexRender(cell.column.columnDef.cell, cell.getContext());

  if (!tooltip) {
    return <td className={classes}>{content}</td>;
  }

  return (
    <TooltipOnTruncatedCell tooltipProps={{ content, className: 'top-5' }} className={classes}>
      {content}
    </TooltipOnTruncatedCell>
  );
};
