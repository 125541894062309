import type { CSSProperties } from 'react';
import { useLayoutEffect, useState } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import type { Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';

import { TableCell } from './TableCell';

const MIN_HEADER_HEIGHT = 48.5;

interface TableBodyProps<TData> {
  rows: Row<TData>[];
}

export const TableBody = <TData,>({ rows }: TableBodyProps<TData>) => {
  const [headerHeight, setHeaderHeight] = useState(MIN_HEADER_HEIGHT);

  useLayoutEffect(() => {
    const header = document.getElementById('table-header');
    if (header) {
      setHeaderHeight(header.clientHeight === 0 ? MIN_HEADER_HEIGHT : header.clientHeight);
    }
  }, []);

  const anyIsExpanded = rows.some((row) => row.getIsExpanded());

  return (
    <tbody className="bg-white">
      {rows.map((row) => {
        const isParent = !row.parentId;
        const isSticky = isParent && anyIsExpanded;
        const styleProps: CSSProperties = {
          position: isSticky ? 'sticky' : 'relative',
          top: isSticky ? `calc(${headerHeight}px * 0.97)` : undefined,
        };

        return (
          <tr
            data-testid="table-row"
            data-row-id={row.id}
            key={row.id}
            className={clsx(
              'micro-interactions border-y border-info-200 hover:bg-primary-50',
              isParent ? 'bg-white' : 'bg-info-50',
              isSticky ? 'z-10' : '',
            )}
            style={styleProps}
          >
            {row.getVisibleCells().map((cell) => (
              <ShowFirstMatching key={cell.id}>
                <Show when={cell.column.columnDef.meta?.isStandaloneDataCell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Show>
                <Show when={!cell.column.columnDef.meta?.isStandaloneDataCell}>
                  <TableCell cell={cell} />
                </Show>
              </ShowFirstMatching>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};
