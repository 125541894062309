import type { FC } from 'react';
import Button from '@components/Button';
import { useNavigation } from '@ContractBuilder/modules/navigation';
import { SectionModalController } from '@ContractBuilder/modules/navigation/modules/section-modal/controller/SectionModalController';
import { CursorClickIcon } from '@heroicons/react/outline';

export const TemplatePlaceholder: FC = () => {
  const { openSectionModal } = useNavigation();

  const handleClick = () => {
    openSectionModal();
  };

  return (
    <>
      <SectionModalController />
      <div className="page-A4-width mx-auto">
        <div className="mt-28 flex flex-col items-center gap-6">
          <CursorClickIcon height={48} width={48} className="text-gray-400" />
          <p className="prose-lg font-medium">No content</p>
          <p className="text-sm text-info-700">Create a Part to get started and then add blocks inside it</p>
          <Button kind="primary" onClick={handleClick}>
            New Part
          </Button>
        </div>
      </div>
    </>
  );
};
