import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { CreateRenewalPayload } from '@root/@types/types';
import axios from 'axios';

interface CreateRenewalResult {
  id: string;
  movedBlockIds: string[];
}

export const createRenewal = async (data: CreateRenewalPayload): Promise<CreateRenewalResult> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: `submissions-spa-al`,
    data,
  });

  const response = await axios(networkPayload);
  return response?.data as CreateRenewalResult;
};
