export const CLAUSE_TYPES = [
  'Condition',
  'Endorsement',
  'Exclusion',
  'Express Warranties',
  'Standard Warranties',
  'Wording',
] as const;

export const CLAUSE_TYPE_TO_DATAPOINT_ID = {
  Condition: 'list_of_condition',
  Endorsement: 'list_of_endorsement',
  Exclusion: 'list_of_exclusion',
  'Express Warranties': 'list_of_express_warranties',
  'Standard Warranties': 'list_of_standard_warranties',
  Wording: 'list_of_wording',
};
