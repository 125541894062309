import type { FC } from 'react';
import React from 'react';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import Modal from '@components/Modal';
import Tooltip from '@components/Tooltip';
import { XIcon } from '@heroicons/react/solid';
import { mdiAlertOutline } from '@mdi/js';
import clsx from 'clsx';

interface ReplaceBaseTemplateModalProps {
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export const ReplaceBaseTemplateModal: FC<ReplaceBaseTemplateModalProps> = ({ isLoading, onClose, onConfirm }) => {
  return (
    <Modal
      className="w-[600px]"
      onClose={onClose}
      open
      title={
        <div className="flex w-full items-center gap-x-2">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-amber-50">
            <IconMdi path={mdiAlertOutline} size={0.72} className={clsx('text-amber-600')} />
          </div>
          <h3>Are you sure you want to change the template?</h3>
          <Tooltip content="Close modal" disabled={isLoading}>
            <XIcon
              className="ml-auto h-7 w-7 translate-x-[8px] cursor-pointer rounded-md p-1 text-info-500 transition-colors hover:bg-info-100"
              onClick={onClose}
            />
          </Tooltip>
        </div>
      }
      shouldShowCloseIcon={false}
      titleClassName="text-lg leading-6 font-medium"
    >
      <div className="flex flex-col pl-10 text-sm font-normal leading-5 text-info-500">
        <p>
          Changing the base template for this contract will remove all edited content, including additional text,
          formatting changes, added blocks, and clauses.
        </p>
        <br />
        <p>
          <strong>This action is irreversible, and only the form data will be retained.</strong>
        </p>
        <div className="mt-12 flex justify-end gap-x-2">
          <Button className="h-[2.375rem]" isDisabled={isLoading} kind="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button
            className="h-[2.375rem]"
            loading={isLoading}
            kind="primary"
            onClick={onConfirm}
            size="sm"
            type="buttom"
          >
            Change template
          </Button>
        </div>
      </div>
    </Modal>
  );
};
