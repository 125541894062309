import { getChildrenWithPath } from '@root/helpers/schema/getChildrenWithPath';
import { isMultiSelect } from '@root/helpers/schema/is-multi-select';
import type { SchemaObject } from 'json-schema-traverse';
import { cloneDeep, get, mergeWith, pick } from 'lodash-es';

import ajv from './ajv';
import { ROOT_SECTIONS_KEY, SPLIT_BY_SECTIONS_KEY } from './constants';
import { sortByTitle } from './sort-by-title';
import type { ResolvedSchemaField, ResolvedSchemaTree } from './types';

type TraverseFunc = (
  schema: SchemaObject,
  path: string,
  parentSchema: SchemaObject | null,
) => ResolvedSchemaTree[] | ResolvedSchemaField[];

const customizer = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue.length === 0 ? [] : objValue.concat(srcValue.filter((item: any) => objValue.indexOf(item) === -1));
  }
};
const getFullFieldPath = (path: string = '') =>
  `${path
    .replace(/(\.properties|\.items|\.SubmissionForm)(?=\.)/g, '')
    .replace(/\.$/, '')
    .substring(1)}`;

const CREATE_OBJECT_FROM_SCHEMA_COPY_KEYS = [
  '$ref',
  'cdrId',
  'cdrName',
  'displayTitleForYesAnswer',
  'format',
  'helperText',
  'maxLength',
  'minLength',
  'pattern',
  'placeholderText',
  'thousandSeparator',
  'type',
  'ui:component',
  'ui:textTransform',
];
export const isRepeater = (type: string = '') =>
  ['SectionRepeater', 'ToggleableSectionRepeater', 'Repeater'].includes(type);
export const isSectionRepeater = (type: string = '') => type === 'ToggleableSectionRepeater';
export const isContentRepeater = (type: string = '') => ['SectionRepeater', 'Repeater'].includes(type);
export const isInlineContentRepeater = (type: string = '') => type === 'Repeater';
export const isTableRepeater = (type: string = '') => ['SectionRepeater', 'ToggleableSectionRepeater'].includes(type);
export const isEnumOrCountrySubDivision = (item: Pick<ResolvedSchemaField, 'enum' | 'ui:component' | '$ref'>) => {
  const type = item['ui:component'] ?? '';
  const isBinaryChoice = item.enum && item['ui:component'] === 'InputToggleGroup';
  const isCountrySubDivision = type === 'InputCountrySubDivision';

  return (('enum' in item || '$ref' in item) && !isBinaryChoice) || isCountrySubDivision;
};

const createObjectFromSchema = (
  schema: SchemaObject,
  path = '',
  titleSuffix = '',
  parentSchema: SchemaObject | null = null,
): ResolvedSchemaTree => {
  const id = getFullFieldPath(path);
  const propertyName = id.split('.').pop();
  const isRequired = Boolean(parentSchema?.required?.includes(propertyName));
  const extraFields = pick(schema, CREATE_OBJECT_FROM_SCHEMA_COPY_KEYS);

  // Swap plural to singular title for content repeaters
  const parsedTitle = isContentRepeater(schema['ui:component']) && schema?.items ? schema?.items?.title : schema?.title;

  return {
    ...(schema.enum ? { enum: schema.enum } : {}),
    ...(schema?.items?.enum ? { items: { enum: schema.items.enum } } : {}),
    ...extraFields,
    ...(isRepeater(schema['ui:component']) ? { type: schema['ui:component'] } : {}),
    id,
    isRequired,
    qid: schema.qid,
    title: parsedTitle + titleSuffix,
    ...(schema['ui:component'] === 'InputCheckbox' ? { title: schema?.enum[0] } : {}),
    mode: schema.mode,
    children: [],
    'ui:component': schema['ui:component'],
  };
};

// Generic function to handle 'properties' or 'items'
const handleSubSchema = (
  subSchema: SchemaObject,
  schema: SchemaObject,
  path: string,
  isItems: boolean,
  traverseFunc: TraverseFunc,
  formValues: Record<string, any>,
) => {
  const sectionsToSplit = get(formValues, SPLIT_BY_SECTIONS_KEY, []);
  const numberOfSections = get(formValues, `${ROOT_SECTIONS_KEY}.length`, 1);

  const shouldRepeatForSections =
    isSectionRepeater(schema['ui:component']) && sectionsToSplit.includes(getFullFieldPath(path));
  const shouldRepeatContent = isRepeater(schema['ui:component']) && get(formValues, getFullFieldPath(path))?.length > 0;

  const repeatCount = shouldRepeatForSections
    ? numberOfSections
    : shouldRepeatContent
    ? get(formValues, getFullFieldPath(path))?.length
    : 1;

  const result = [];

  for (let i = 0; i < repeatCount; i++) {
    const titleSuffix = shouldRepeatForSections ? ` - Section ${i + 1}` : shouldRepeatContent ? ` ${i + 1}` : '';
    const obj = createObjectFromSchema(schema, path, titleSuffix);

    if (isItems) {
      obj.children.push(...traverseFunc(subSchema, `${path}.items.${i}`, schema));
    } else {
      for (const key in subSchema) {
        obj.children = obj.children.concat(traverseFunc(subSchema[key], `${path}.properties.${key}`, schema));
      }
    }

    result.push(obj);
  }

  return result;
};

const isOtherField = (path: string) => path.endsWith('_other');

const resolveConditionalFields = (
  schema: SchemaObject,
  formValues: Record<string, any> = {},
  path: string,
  forceConditionalFields = false,
  includeOthers = false,
) => {
  if (schema.allOf) {
    const resolvedAllOf = schema.allOf.map((subSchema: any) =>
      resolveConditionalFields(subSchema, formValues, path, forceConditionalFields, includeOthers),
    );
    return mergeWith(cloneDeep(schema), ...resolvedAllOf, customizer);
  }
  if (!schema.if) return schema;

  const isSubSchemaValid = ajv.compile(schema.if)(get(formValues, getFullFieldPath(path)) || {});
  const isOther = Object.keys(schema?.then?.properties || {}).find((key) => key.endsWith('_other')) != null;

  if ((isSubSchemaValid || forceConditionalFields) && (includeOthers ? true : !isOther))
    return mergeWith(cloneDeep(schema), schema.then, customizer);

  return schema;
};

export const resolveSchemaTreeFromForm = (
  schema: SchemaObject,
  formValues: Record<string, any> = {},
  forceConditionalFields = false,
  skipObjectWithNoTitle = true,
  includeOthers = false,
): ResolvedSchemaTree[] => {
  const traverseSchema = (schema: SchemaObject, path = '', parentSchema: SchemaObject | null = null) => {
    let result: ResolvedSchemaTree[] = [];
    const shouldSkip = (skipObjectWithNoTitle && !schema.title) || /items\.\d$/.test(path);

    if (schema.properties) {
      schema = resolveConditionalFields(schema, formValues, path, forceConditionalFields, includeOthers);
      if (shouldSkip) {
        for (const key in schema.properties) {
          result = result.concat(traverseSchema(schema.properties[key], `${path}.properties.${key}`, schema));
        }
      } else {
        result = result.concat(handleSubSchema(schema.properties, schema, path, false, traverseSchema, formValues));
      }
    } else if (schema.items && !isMultiSelect(schema['ui:component'])) {
      if (shouldSkip) {
        result = result.concat(traverseSchema(schema.items, `${path}.items`, schema));
      } else {
        result = result.concat(handleSubSchema(schema.items, schema, path, true, traverseSchema, formValues));
      }
    } else {
      result.push(createObjectFromSchema(schema, path, '', parentSchema));
    }

    return result;
  };

  return traverseSchema(cloneDeep(schema));
};

export const isResolvedSchemaField = (
  field: ResolvedSchemaField | ResolvedSchemaTree,
): field is ResolvedSchemaField => {
  return 'id' in field && field.children.length === 0 && !isRepeater(field.type);
};

export const isLeafField = (field: any) => {
  return (
    Object.values(field?.properties ?? {}).length === 0 && !isRepeater(field['ui:component']) && !isOtherField(field.id)
  );
};

export const flattenResolvedSchemaTree = (tree: ResolvedSchemaTree[]): ResolvedSchemaField[] => {
  const result: ResolvedSchemaField[] = [];

  function flatten(item: ResolvedSchemaTree | ResolvedSchemaField) {
    if (isResolvedSchemaField(item)) {
      result.push(item);
    }

    if (Array.isArray(item)) {
      item.forEach((child) => flatten(child));
    }

    if (item.children && item.children.length > 0) {
      item.children.sort(sortByTitle).forEach((child) => flatten(child));
    }
  }

  tree.forEach(flatten);

  return result;
};

export const getSectionRepeatersFromSchema = (tree: ResolvedSchemaTree[]): ResolvedSchemaTree[] => {
  const result: ResolvedSchemaTree[] = [];

  function flatten(item: ResolvedSchemaTree | ResolvedSchemaField) {
    if (isRepeater(item.type)) {
      result.push(item);
    }

    if (Array.isArray(item)) {
      item.forEach((child) => flatten(child));
    }

    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => flatten(child));
    }
  }

  tree.forEach(flatten);

  return result;
};

export const getSectionRepeatersFromOriginalSchema = (tree: any): any => {
  const result: any = [];

  function flatten(item: any) {
    if (isRepeater(item['ui:component'])) {
      result.push(item);
    }

    if (Array.isArray(item)) {
      item.forEach((child) => flatten(child));
    }

    if (!Array.isArray(item)) {
      const children = getChildrenWithPath(item, item.id);

      if (children.length > 0) {
        children.forEach((child) => flatten(child));
      }
    }
  }

  tree.forEach(flatten);

  return result;
};
