import React from 'react';
import { EndorsementsCountBadge } from '@pages/User/components';
import type { ColumnHelper } from '@tanstack/react-table';

export const endorsementsAccessor = <T extends { endorsementsCount?: number }>(columnHelper: ColumnHelper<T>) =>
  columnHelper.accessor((entity) => entity.endorsementsCount, {
    id: 'endorsementsCount',
    header: 'ENDT',
    cell: (cellContext) => <EndorsementsCountBadge count={cellContext.getValue()} />,
    enableColumnFilter: false,
    sortUndefined: 'last',
    meta: {
      headingCellClassName: '2xl:w-24',
      showAboveBreakpoint: 'xl',
    },
  });
