import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { CreateRenewalFormState } from '../types';

export const useCreateRenewalForm = (
  defaultName: string = '',
  currentTemplateId?: string,
  canRefreshCurrentTemplate = true,
) => {
  const { control, handleSubmit, watch, setValue } = useForm<CreateRenewalFormState>({
    defaultValues: {
      name: defaultName,
      type: canRefreshCurrentTemplate ? 'refresh' : 'replicate',
    },
  });

  const formValues = watch();

  useEffect(() => {
    if (!formValues.type) {
      return;
    }

    if (formValues.type === 'replicate') {
      setValue('skipPreviouslyRemovedBlocks', false);
      setValue('keepPreviouslyAddedBlocks', false);
      setValue('baseTemplateId', undefined);
    } else if (formValues.type === 'refresh' && currentTemplateId) {
      setValue('baseTemplateId', currentTemplateId);
    } else {
      setValue('baseTemplateId', undefined);
    }
  }, [formValues.type, currentTemplateId, setValue]);

  return {
    control,
    formValues,
    handleSubmit,
  };
};
