import type { Control } from 'react-hook-form';
import type { EntityData } from '@ContractBuilder/types';
import type { TemplateShort, WithRequired } from '@root/@types/types';
import type { z } from 'zod';

import type { validationSchema } from './validation/schema';

export type CreateRenewalFormState = z.infer<typeof validationSchema>;

export interface PublishedTemplate extends Omit<WithRequired<TemplateShort, 'name'>, 'status'> {
  status: 'PUBLISHED';
}

export interface StepProps {
  canRefreshCurrentTemplate: boolean;
  control: Control<CreateRenewalFormState>;
  formValues: CreateRenewalFormState;
  isLoading: boolean;
  step: Step;
  submission?: EntityData;
  templates: PublishedTemplate[];
}

export enum Step {
  Base = 1,
  Type = 2,
  Config = 3,
}
