import { type FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useEntityStore } from '@ContractBuilder/store';

import { ReplaceBaseTemplateModal } from '../views/ReplaceBaseTemplateModal';

interface ReplaceBaseTemplateModalControllerProps {
  handleClose: () => void;
  onUpdateSuccess: () => void;
  targetResourceId: string;
}

export const ReplaceBaseTemplateModalController: FC<ReplaceBaseTemplateModalControllerProps> = ({
  handleClose,
  onUpdateSuccess,
  targetResourceId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const changeDocumentBase = useEntityStore((store) => store.changeDocumentBase);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const response = await changeDocumentBase(targetResourceId);

      if (response?.message === 'success') {
        toast.success('Successfully updated the base template.', { autoClose: 5000 });
      }
    } finally {
      setIsLoading(false);
    }

    onUpdateSuccess();
    return handleClose();
  };

  return <ReplaceBaseTemplateModal isLoading={isLoading} onClose={handleClose} onConfirm={handleConfirm} />;
};
