import pointer from 'json-pointer';
import traverse from 'json-schema-traverse';
import { mergeWith, unset } from 'lodash-es';

export const resolveRefs = (schema: any) => {
  traverse(schema, {
    cb: (currSchema) => {
      if ('$ref' in currSchema) {
        const pathToDefinition = currSchema?.['$ref']?.split('#')?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, '$ref');
      }
    },
  });
};
