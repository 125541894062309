import { type FC } from 'react';
import H3 from '@components/H3';
import { InlineEditableText } from '@components/InlineEditableText';
import InputText from '@components/InputText';
import { documentTitleClasses } from '@ContractBuilder/contract-builder-header/classes';
import { EntityField } from '@ContractBuilder/contract-builder-header/constants';
import {
  type OnSaveFn,
  useEditableField,
} from '@ContractBuilder/contract-builder-header/modules/entity-details/hooks/use-editable-field';
import type { UIInputValue } from '@root/@types/types';
interface BrandingEditableNameFieldProps {
  value?: string;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn<string | undefined>;
}

const PLACEHOLDER = 'Branding name';

export const BrandingEditableNameField: FC<BrandingEditableNameFieldProps> = ({
  value,
  hasOperationsDisabled,
  onSave,
}) => {
  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField({
    name: EntityField.Name,
    defaultValue: value,
    onSave,
  });

  return (
    <InlineEditableText
      editor={
        <InputText
          value={state.value as UIInputValue}
          onChange={(event) => onChange(event.target.value)}
          name="branding-name"
          className="[&>div>input]:h-8"
          placeholder={PLACEHOLDER}
        />
      }
      editMode={state.isEditing}
      disabled={hasOperationsDisabled}
      className="min-w-0"
      display={
        <H3
          className={documentTitleClasses({
            isDisabled: hasOperationsDisabled,
            isPlaceholder: !value,
          })}
        >
          {value}
        </H3>
      }
      onClick={onClick}
      onCancel={onCancel}
      onConfirm={onUpdate}
      tooltipContent={value}
      withEditIcon={false}
    />
  );
};
