import { useEffect, useState } from 'react';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import { BlockContent } from '@ContractBuilder/modules/block-content';
import { useBlockActions } from '@ContractBuilder/modules/block-edit';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { ctxIsPDF } from '@ContractBuilder/rules/block/is-ctx';
import type { BlockVariation } from '@root/@types/database';
import type { ResourceBlock } from '@root/@types/types';
import { upliftVariation } from '@root/helpers/variations';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import { useDeepCompareMemo } from '@src/hooks';
import { isEmpty } from 'lodash-es';

import { VariationTabLite } from '../components/VariationTabLite';
import type { VariationsTabsContextLiteValue } from '../context/context-lite';
import { VariationsTabsContextLite } from '../context/context-lite';

import '../index.css';

interface VariationsProviderLiteProps {
  block: ResourceBlock;
}

export const VariationsSelectorLite = ({ block }: VariationsProviderLiteProps) => {
  const { updateBlock } = useBlockActions();
  const [loading, setLoading] = useState(false);

  const {
    context,
    isHighlighted,
    details: { isEditing, isVisible, isParentEntityEditable },
  } = useBlockFlags();

  const { variations, id: blockId, selectedVariationId } = block || {};
  const isPDFRender = ctxIsPDF(context);

  const { currentVariationIdx, setCurrentVariationIdx } = useVariationsTabsStore(
    ({ getBlockCurrentVariationIdx, setCurrentVariationIdx }) => ({
      currentVariationIdx: getBlockCurrentVariationIdx(blockId),
      setCurrentVariationIdx,
    }),
  );

  const select = (newVariationId: string) => {
    const isAlreadySelected = newVariationId === block?.selectedVariationId;

    if (isAlreadySelected) {
      return;
    }

    setLoading(true);

    const payload = upliftVariation(block, newVariationId);
    updateBlock({ ...payload, id: blockId }).finally(() => setLoading(false));
  };

  const setCurrent = (id?: string) => {
    const { variations = [] } = block ?? {};
    if (!variations.length || !id) {
      setCurrentVariationIdx(blockId, 0);
      return;
    }

    const variation = variations.find((v) => v.id === id);
    const idx = variation ? variations.indexOf(variation) : 0;
    setCurrentVariationIdx(blockId, idx);
  };

  const variationsCount = variations?.length ?? 0;

  useEffect(() => {
    if (variationsCount === currentVariationIdx) {
      setCurrentVariationIdx(blockId, variationsCount - 1);
    }
  }, [blockId, currentVariationIdx, setCurrentVariationIdx, variationsCount]);

  const blockPreview = useDeepCompareMemo(() => {
    const variation = variations?.[currentVariationIdx];
    if (!variation) return block;
    const { title, content, linkedDatapoints = [] } = variation;
    return { ...block, title, content, linkedDatapoints };
    // eslint-disable-next-line -- Run only when either editing block or current variation changes
  }, [block, currentVariationIdx]);

  const value: VariationsTabsContextLiteValue = useDeepCompareMemo(
    () => ({
      currentVariationIdx,
      hasLogic: !isEmpty(block?.visibility?.variations),
      loading,
      select,
      selectedVariationId,
      setCurrent,
      variationInView: variations?.[currentVariationIdx]?.id,
      variationsCount,
      visibilityDetails: block?.visibilityComputed?.variations,
    }),
    // eslint-disable-next-line -- The provided list should cover all the cases when we want the context to recalculate
    [block?.visibility?.variations, currentVariationIdx, loading, selectedVariationId, variationsCount],
  );
  const dataTestId = getDataTestId('variations.tabsRow', block?.title);

  return (
    <VariationsTabsContextLite.Provider value={value}>
      <div
        key={`collapsible-item-${blockId}`}
        className="appearance--variations-tab-row-lite"
        data-testid={dataTestId}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex w-full overflow-x-auto bg-info-200/75">
          {variations?.map((entry: BlockVariation) => <VariationTabLite key={entry.id} variation={entry} />)}
        </div>
      </div>
      <BlockContent
        block={blockPreview}
        disableMentionHighlight={!isParentEntityEditable && !isPDFRender}
        isEditing={isEditing}
        isHighlighted={isHighlighted}
        isInCreateMode={isEditing && !block?.id}
        isPdfRender={false}
        isVisible={isVisible}
      />
    </VariationsTabsContextLite.Provider>
  );
};
