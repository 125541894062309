import type { FC } from 'react';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useCurrentContractSections } from '@src/hooks';

import { BlockConfigurationPanel } from '../views/BlockConfigurationPanel';

interface BlockConfigurationPanelControllerProps {
  onCancel: () => void;
  onSubmit: () => void | Promise<void>;
  shouldShowSections?: boolean;
}

export const BlockConfigurationPanelController: FC<BlockConfigurationPanelControllerProps> = ({
  onCancel,
  onSubmit,
  shouldShowSections = true,
}) => {
  const { formValues: state } = useBlockEditFormStore(({ formValues }) => ({
    formValues,
  }));
  const sections = useCurrentContractSections();

  const sectionOptions = shouldShowSections ? sections.map(({ id, label }) => ({ name: label, value: id })) : [];
  const currentSection = sectionOptions?.find((item) => item.value === state?.section_id);

  if (shouldShowSections && (!currentSection || !sectionOptions)) {
    return null;
  }

  const handleSubmit = async () => {
    await onSubmit();
  };

  return <BlockConfigurationPanel onCancel={onCancel} onSubmit={handleSubmit} sectionOptions={sectionOptions} />;
};
