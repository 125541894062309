import React, { useEffect, useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import countrySubdivisions from '@root/helpers/schema/country_subdivisions.json';
import { cloneDeep, get, unset } from 'lodash-es';

import type { InputMultiSelectSchemaProps } from './InputMultiSelect';
import InputMultiSelect from './InputMultiSelect';
import InputSelect from './InputSelect';

interface InputCountrySubDivisionProps extends Omit<InputMultiSelectSchemaProps, 'labelText'> {
  item: {
    items: {
      type: string;
      enum: string[];
    };
    helperText?: string;
    shortName?: string;
    type: string;
    showAll?: boolean;
  };
  labelText?: string;
  ownKey: string;
}

const getCountrySubdivisionOptions = (countryValue: string) => {
  return get(countrySubdivisions, `${countryValue}.enum`, []);
};

const getAllCountrySubdivisionOptions = () => {
  const subDivisions: any[] = [];
  Object.keys(countrySubdivisions).forEach((countryValue) => {
    subDivisions.push(get(countrySubdivisions, `${countryValue}.enum`, []));
  });
  return subDivisions;
};

const InputCountrySubDivision: React.FC<InputCountrySubDivisionProps> = ({
  item,
  initialSelectedItem,
  isReadOnly,
  ...props
}) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);

  const countryKey = ownKey.replace('_sub_division', '');
  const { countryValue, setFormValues, value, isIncomplete } = useDatapointsStore(({ formValues, setFormValues }) => ({
    countryValue: get(formValues, countryKey),
    setFormValues,
    ...selector({ formValues }),
  }));

  let options: Array<string> = Array.isArray(countryValue)
    ? countryValue.map((item) => getCountrySubdivisionOptions(item)).flat()
    : get(countrySubdivisions, `${countryValue}.enum`, []);
  const isDisabled = (props.isDisabled || countryValue === undefined || options.length === 0) && !item.showAll;
  if (item.showAll === true) {
    options = getAllCountrySubdivisionOptions().flat();
  }

  useEffect(() => {
    if (!isReadOnly) {
      if (options.includes(value)) return;
      setFormValues((current) => {
        const clonedFormValues = cloneDeep(current);
        unset(clonedFormValues, ownKey);
        return clonedFormValues;
      });
    }
    // eslint-disable-next-line -- We only really want to re-run the logic when `countryValue` changes
  }, [countryValue, isReadOnly]);

  if (item.type === 'array') {
    return (
      <InputMultiSelect
        {...props}
        isIncomplete={isIncomplete}
        initialSelectedItem={initialSelectedItem}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isSearchable={true}
        item={{ ...item, items: { ...item.items, enum: options } }}
        ownKey={ownKey}
      />
    );
  }

  return (
    // @ts-expect-error
    <InputSelect
      {...props}
      isIncomplete={isIncomplete}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      isSearchable={true}
      item={{ ...item, enum: options.map((value) => ({ name: value, value })) }}
      ownKey={ownKey}
      value={value}
    />
  );
};

export default InputCountrySubDivision;
