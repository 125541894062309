import { cva } from 'class-variance-authority';

export const radioOptionClasses = cva('transition flex p-4 gap-3', {
  variants: {
    isChecked: {
      true: 'bg-blue-100 border-blue-200',
      false: 'border-info-200',
    },
  },
});

export const checkboxDotClasses = cva('mt-0.5 h-4 w-4 rounded-full', {
  variants: {
    isChecked: {
      true: 'border-[5px] border-blue-600',
      false: 'border border-info-300',
    },
  },
});

export const descriptionTextClasses = cva('text-sm leading-5 font-normal', {
  variants: {
    isChecked: {
      true: 'text-blue-700',
      false: 'text-info-500',
    },
  },
});

export const titleTextClasses = cva('text-sm font-medium leading-5', {
  variants: {
    isChecked: {
      true: 'text-blue-900',
      false: 'text-info-900',
    },
  },
});
