import { useQuery } from 'react-query';
import type { EntityData } from '@ContractBuilder/types';
import { fetchTemplates } from '@queries/fetchTemplates';

import { filterPublishedTemplates } from '../utils/filterPublishedTemplates';
import { isTemplateResource } from '../utils/isTemplateResource';

export const useTemplatesData = (submission?: EntityData) => {
  const { data: templates = [], isLoading: isFetchingTemplates } = useQuery(['templates'], fetchTemplates, {
    refetchOnWindowFocus: false,
  });

  const publishedTemplates = templates.filter(filterPublishedTemplates);
  const canRefreshCurrentTemplate =
    isTemplateResource(submission?.sourceResourceName) &&
    publishedTemplates.some((template) => template.id === submission?.sourceResourceId);

  return { templates: publishedTemplates, canRefreshCurrentTemplate, isFetchingTemplates };
};
