import type { FC } from 'react';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useUserStore } from '@Auth/store';
import Button from '@components/Button';
import InputMultiSelect from '@components/InputMultiSelect';
import InputSelect from '@components/InputSelect';
import InputText from '@components/InputText';
import Modal from '@components/Modal';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { createUserPayloadSchema, updateUserPayloadSchema } from '@root/@types/ajv/user';
import type { TransformedCognitoUser } from '@root/@types/cognito';
import { UserRole } from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';
import { createUser, updateUser } from '@src/mutations';
import clsx from 'clsx';

interface UserModalProps {
  handleClose: () => void;
  isAdd: boolean;
  user: TransformedCognitoUser;
}

const USER_ROLES = [UserRole.Admin, UserRole.User, UserRole.Superadmin];
const TEAMS = tenantConfig.teams ?? [];

const UserModal: FC<UserModalProps> = ({ user, handleClose, isAdd }) => {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState(user ?? {});
  const [isLoading, setIsLoading] = useState(false);
  const { user: currentUser } = useUserStore(({ user }) => ({ user }));
  const { refreshUser } = useUserStore(({ refreshUser }) => ({
    refreshUser,
  }));

  const getFeatureFlagState = useFeatureFlags();
  const isSSOEnabled = getFeatureFlagState('SSO');
  const externalIdentityProvider = import.meta.env.VITE_EXTERNAL_IDENTITY_PROVIDER;

  const identityProviders = [
    {
      id: 'internal',
      name: 'Username and Password',
      description: 'Users can sign up with a username and password combination',
    },
    {
      id: `azure-${import.meta.env.VITE_CLIENT}-${import.meta.env.VITE_ENVIRONMENT}`,
      name: 'Azure Active Directory',
      displayName: 'Azure AD',
      description: 'The user can sign up with their Azure Active Directory account',
    },
  ];

  const handleChange = (name: string) => (event: any) => {
    if (name === 'teams') {
      setFormValues({
        ...formValues,
        [name]: event?.map((teamName: string) => TEAMS?.find((team) => team.name === teamName)?.id),
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: name === 'role' || name === 'identity_provider' ? event : event.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { id, username, first_name, last_name, email, role, teams, identity_provider } = formValues;

      if (isAdd) {
        await createUser(formValues);
      } else {
        const userId = formValues?.sub ?? username ?? id;
        const updatedUser = await updateUser(userId, {
          action: 'update',
          first_name,
          last_name,
          email,
          role,
          teams,
          identity_provider,
        });
        if (currentUser?.username === userId) {
          await refreshUser(updatedUser.updated_at);
        }
      }

      handleClose();
      await queryClient.invalidateQueries({ queryKey: ['users'] });
    } catch {
      /* empty */
    } finally {
      setIsLoading(false);
    }
  };

  const isPendingExternalUser = !isAdd && formValues.identity_provider !== 'internal' && !formValues.status;

  const handleToggleEnabledStateOnUser = async () => {
    if (isPendingExternalUser) {
      return;
    }
    try {
      handleClose();
      const { id, enabled } = formValues;
      await updateUser(formValues?.sub ?? id, { action: 'change-state', enabled: !enabled });
      await queryClient.invalidateQueries({ queryKey: ['users'] });
    } catch {
      /* empty */
    }
  };

  const isSubmitDisabled = (requiredFields: string[], values: any) =>
    requiredFields.find((fieldName: string) => !values?.[fieldName]?.trim()) !== undefined;

  return (
    <Modal open className="w-full sm:w-5/6 lg:w-1/2" onClose={handleClose} title={isAdd ? 'Create user' : 'Edit user'}>
      <div className="flex flex-col gap-y-2">
        <InputText
          labelText="First Name"
          isRequired
          value={formValues.first_name}
          onChange={handleChange('first_name')}
        />
        <InputText labelText="Last Name" isRequired value={formValues.last_name} onChange={handleChange('last_name')} />
        <InputText
          labelText="Email"
          isRequired
          value={formValues.email}
          readOnly={!isAdd}
          onChange={isAdd ? handleChange('email') : () => {}}
        />
        <InputSelect
          labelText="Role"
          isRequired
          options={USER_ROLES.map((i) => ({ name: i, value: i }))}
          onChange={handleChange('role')}
          value={formValues.role}
        />
        {TEAMS.length > 0 && (
          <InputMultiSelect
            labelText="Teams"
            options={TEAMS.map((team) => team.name)}
            onChange={handleChange('teams')}
            onSelect={() => {}}
            value={formValues.teams?.map((teamId) => TEAMS?.find((team) => team.id === teamId)?.name ?? '')}
          />
        )}
        {isSSOEnabled && externalIdentityProvider && (
          <InputSelect
            labelText="Sign Up Method"
            isReadOnly={!isAdd}
            options={identityProviders.map((i) => ({ name: i.name, value: i.id }))}
            onChange={isAdd ? handleChange('identity_provider') : () => {}}
            value={identityProviders?.find((i) => i.id === formValues?.identity_provider)?.name ?? ''}
          />
        )}
        {isAdd ? (
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              disabled={isSubmitDisabled(createUserPayloadSchema.required, formValues) || isLoading}
              onClick={handleSubmit}
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  sm:ml-3 sm:w-auto sm:text-sm"
            >
              Create
            </button>
            <button
              onClick={handleClose}
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-info-300 bg-white px-4 py-2 text-base font-medium text-info-700 shadow-sm hover:bg-info-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex flex-row">
            <div className="w-1/2 basis-1/2">
              <div className="mt-5 sm:mt-4 sm:flex">
                <Button
                  className={clsx(
                    'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm',
                    formValues.enabled
                      ? 'bg-error-600 hover:bg-error-700 focus:ring-error-500'
                      : 'bg-green-600 hover:bg-green-700 focus:ring-green-500',
                  )}
                  isDisabled={isLoading || isPendingExternalUser}
                  onClick={handleToggleEnabledStateOnUser}
                  type="button"
                >
                  {formValues.enabled ? 'Disable' : 'Enable'}
                </Button>
              </div>
            </div>
            <div className="w-1/2 basis-1/2">
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2  disabled:text-white sm:ml-3 sm:w-auto sm:text-sm"
                  loading={isLoading}
                  onClick={handleSubmit}
                  type="button"
                  isDisabled={isSubmitDisabled(updateUserPayloadSchema.required, { ...formValues, action: 'update' })}
                >
                  Submit
                </Button>
                <Button
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-info-300 bg-white px-4 py-2 text-base font-medium text-info-700 shadow-sm hover:bg-info-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  isDisabled={isLoading}
                  onClick={handleClose}
                  type="button"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UserModal;
