export const LoadingSkeleton = () => {
  return (
    <div aria-live="polite" className="flex w-full animate-pulse flex-col">
      <div className="mb-2 h-5 w-1/2 rounded-md bg-slate-200" />
      <div className="mb-[16px] h-10 w-full rounded-md bg-slate-200" />
      <div className="mt-6 flex justify-between gap-4">
        <div className="h-[38px] w-1/2 rounded-md bg-slate-200" />
        <div className="h-[38px] w-1/2 rounded-md bg-slate-200" />
      </div>
    </div>
  );
};
