import type { FC, MouseEventHandler } from 'react';
import { useCallback } from 'react';
import { FeatureIndicatorIcon } from '@components/FeatureIndicatorIcon';
import LoadingOverlay from '@components/LoadingOverlay';
import { Show } from '@components/Show';
import { EDITING_BLOCK_Z_INDEX } from '@constants/z-indices';
import { BlockCreatorController } from '@ContractBuilder/modules/block-creator';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { DrawerLayoutComponent } from '@ContractBuilder/modules/drawer-layout';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { ctxIsPDF, ctxIsTemplate } from '@ContractBuilder/rules/block/is-ctx';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import type { EntityData } from '@ContractBuilder/types';
import {
  type DocumentContext,
  getEndorsementMentionValues,
  getLockedBlockPermissionsMessage,
  getMentionValues,
  replaceMention,
} from '@ContractBuilder/utils';
import { useBlockFlagsGenerator } from '@hooks/use-block-flags-generator';
import {
  mdiArrowDecisionAuto,
  mdiAutoFix,
  mdiEye,
  mdiEyeOff,
  mdiFormatPageBreak,
  mdiLock,
  mdiPaletteSwatchVariant,
  mdiTuneVertical,
} from '@mdi/js';
import { useSetFocussedCandidate } from '@MrcExtraction/modules/mrc-document-view';
import type { ResourceBlock } from '@root/@types/base';
import type { BlockMentionValues, ResourceName } from '@root/@types/types';
import { getClausesAsMentions, getGroupedClauses, isEndorsement, isNonNullish } from '@root/helpers';
import { isExcludedFromEndorsementPreview } from '@root/helpers/blocks';
import { replaceUnknownMentionVariables } from '@root/helpers/pdf';
import { MODALS } from '@src/constants';
import { useCurrentContractSections, useDeepCompareCallback, useDeepCompareMemo, useModal } from '@src/hooks';
import { isEndorsementViewPath } from '@utils/app-paths';
import clsx from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useEntityStore, useSchemaStore } from '../../../store';
import { indicatorIconsClasses, leftBorderClasses } from '../classes';
import { BlockAlert } from '../components/BlockAlert';
import { BlockPermissionsSet } from '../components/BlockPermissionsSet';
import { BlockUsedWithin } from '../components/BlockUsedWithin';
import { VisibilityReasons } from '../components/VisibilityReasons';
import type { BlockFlagsContextType } from '../context/context';
import { BlockFlagsProvider } from '../context/context';
import { BlockView } from '../view/BlockView';

export interface BlockControllerProps {
  block: ResourceBlock;
  context: DocumentContext;
  disableControls?: boolean;
  endorsementParent?: EntityData;
  order: number;
  schemaTreeForPdf?: any;
  sectionId: string;
  submission: EntityData;
}

export const BlockController: FC<BlockControllerProps> = ({
  block,
  context,
  disableControls = false,
  endorsementParent,
  order,
  schemaTreeForPdf,
  sectionId,
  /** Pass down the submission as a prop rather than pulling it from the store to make it work with PDF render **/
  submission,
}) => {
  const isPDFRender = ctxIsPDF(context);
  const isTemplate = ctxIsTemplate(context);

  const { id, section_id } = block;

  const isEndorsementView = isEndorsementViewPath();
  const entitySections = useCurrentContractSections();

  const onDocumentPreviewOpen = useSetFocussedCandidate();
  const { onCancel, setEditingBlock, setOnCancelCallback, onSubmit } = useBlockEdit();

  const flags = useBlockFlagsGenerator(block, {
    context,
    disableControls,
    isEndorsementView,
  });

  const {
    details: {
      isParentEntityEditable,
      hasVariationsConditionalLogic,
      hasBeenMovedByRenewal,
      isEditable,
      isEditing,
      isVariationActionRequired,
      isVisible,
      renderPageBreak,
      showBlockAlerts,
      showCreator,
      showDataExtractionIndicator,
      showLockedIndicator,
      showPageBreakIndicator,
      showPermissionsSetIndicator,
      showUsedInTemplatesControls,
      showVariationsIndicator,
      showVisibilityReasonsIndicatorIcon,
      variations,
      visibilityDetails,
    },
  } = flags;

  const { schemaTreeList } = useSchemaStore(({ schemaTreeList }) => ({ schemaTreeList }));

  const { closeDrawer, openDrawer } = useDrawerStore(({ closeDrawer, openDrawer }) => ({
    closeDrawer,
    openDrawer,
  }));

  const { isHighlighted, isLoading } = useEntityStore(({ loadingBlockId, highlightedBlockId }) => ({
    isHighlighted: id === highlightedBlockId,
    isLoading: id === loadingBlockId,
  }));

  const { showModal } = useModal();

  const currentVariationTabIdx = useVariationsTabsStore(({ getBlockCurrentVariationIdx }) =>
    getBlockCurrentVariationIdx(block.id),
  );

  const handleEdit: MouseEventHandler<HTMLDivElement> = useDeepCompareCallback(
    (event) => {
      event.stopPropagation();
      const isCurrentlyEditing = useBlockEditFormStore.getState().formValues?.id === id;

      if (!isEditable) {
        return;
      }

      const { target } = event;

      // If the click originated from a mention we want to show the datapoint selector instead of entering edit mode
      if (!isTemplate && target instanceof HTMLSpanElement && target.dataset.type === 'mention') {
        return;
      }

      const section = entitySections.find((item) => item.id === sectionId);

      if (!section || !isEditable) {
        return;
      }

      if (!isCurrentlyEditing) {
        setEditingBlock(id);

        setOnCancelCallback({ fn: closeDrawer });
        openDrawer({
          type: DrawerLayoutComponent.BlockConfigurationPanel,
          props: {
            onCancel: () => onCancel(true),
            onSubmit,
            shouldShowSections: !isEndorsementView,
          },
        });
      }
    },
    // eslint-disable-next-line -- We don't care about any of the functions changing
    [id, isTemplate, isEditable, sectionId, entitySections],
  );

  const handleDelete: MouseEventHandler = useCallback(
    () =>
      showModal(MODALS.CONFIRM_DELETE, {
        id,
        title: block.name,
        type: 'block',
        callback: () => onCancel(true),
      }),
    [id, block.name, onCancel, showModal],
  );

  const visibilityReasonsInner = useCallback(
    ({ onClick }: any) => (
      <FeatureIndicatorIcon
        path={isVisible ? mdiEye : mdiEyeOff}
        content={visibilityDetails?.info}
        data-testid="block-icon-indicator:visibility"
        isInteractive
        onClick={onClick}
        className={'text-info-400'}
      />
    ),
    [isVisible, visibilityDetails?.info],
  );

  const mentionValues = useDeepCompareMemo(() => {
    const usedClauses = getGroupedClauses(submission);

    const blockLinkedDatapointIds = (block.linkedDatapoints ?? []).map(({ id }) => id).filter(isNonNullish);
    const variations = block.variations ?? [];
    const variationsLinkedDatapointsCombined = variations
      .map(({ linkedDatapoints }) => linkedDatapoints)
      .flat()
      .filter(isNonNullish);

    const combinedDatapointIdsSet = new Set([
      ...blockLinkedDatapointIds,
      ...variationsLinkedDatapointsCombined.map(({ id }) => id),
    ]);

    const mentions = getMentionValues(
      submission?.data_items,
      [...combinedDatapointIdsSet],
      isPDFRender ? schemaTreeForPdf : schemaTreeList,
    );

    const endorsementMentionValues = getEndorsementMentionValues(
      submission,
      blockLinkedDatapointIds,
      endorsementParent,
    );
    const clausesList = getClausesAsMentions(usedClauses);

    return { ...mentions, ...endorsementMentionValues, ...clausesList } satisfies BlockMentionValues;
    // eslint-disable-next-line -- Skipping schema tree list changes
  }, [block.linkedDatapoints, submission, isEditing, isPDFRender, endorsementParent]);

  const handleReplaceMentionValues = (content?: string): string | undefined => {
    if (!mentionValues) {
      return content;
    }

    const blockLinkedDatapoints = block.linkedDatapoints?.map(({ id }) => id) ?? [];
    const linkedDatapointIds = [...Object.keys(mentionValues), ...blockLinkedDatapoints];

    if (block?.deleted_at) {
      return content;
    }

    linkedDatapointIds.forEach((id) => {
      content = replaceMention(id, mentionValues[id], content);
    });

    return content;
  };

  useDeepCompareEffect(() => {
    if (!isEditing && !isTemplate) {
      handleReplaceMentionValues();
    }
    // eslint-disable-next-line -- Replace mention values upon change
  }, [block.content, currentVariationTabIdx, isEditing, mentionValues, isEndorsementView, isTemplate]);

  const handleExtractionCandidateClick = () => {
    onDocumentPreviewOpen(block.extractionCandidateId);
  };

  if (isPDFRender && block?.content) {
    block.content = handleReplaceMentionValues(block.content) ?? block.content;
    block.content = replaceUnknownMentionVariables(block.content);
  }

  const value: BlockFlagsContextType = useDeepCompareMemo(
    () => ({ ...flags, block, isHighlighted, isLoading, mentionValues, context }),
    [flags, block, isHighlighted, isLoading, mentionValues, context],
  );

  if (isPDFRender && !isVisible && !isEndorsement(submission?.pk as ResourceName)) {
    return null;
  }

  const isHiddenFromPDFPreview = isExcludedFromEndorsementPreview(block, submission);

  return (
    <BlockFlagsProvider value={value}>
      <div
        className={clsx(
          'group relative',
          isEditing && EDITING_BLOCK_Z_INDEX,
          isEndorsementView && 'remove-interactive-mention',
        )}
        data-test-block-id={block.id}
        {...(isParentEntityEditable ? { 'data-editable': true } : {})}
      >
        <Show when={!isPDFRender}>
          <div
            id="left-border"
            className={leftBorderClasses({
              editing: isEditing,
              editable: isParentEntityEditable,
              disableHoverEffect: !isEditable,
              forceHoverEffect: isVariationActionRequired,
            })}
          />
          <LoadingOverlay active={isLoading} size="sm" />
        </Show>
        <BlockView handleDelete={handleDelete} handleEdit={handleEdit} submission={submission} />
        <Show when={!isPDFRender && !isEditing}>
          <div className={clsx(indicatorIconsClasses)}>
            <Show when={isEndorsementView && isHiddenFromPDFPreview}>
              <FeatureIndicatorIcon
                placement="top"
                path={mdiEyeOff}
                content="This block will be hidden in the PDF"
                data-testid="block-icon-indicator:hidden-in-pdf"
              />
            </Show>
            <Show when={!isEndorsementView}>
              <Show when={showVisibilityReasonsIndicatorIcon}>
                <VisibilityReasons visibilityDetails={visibilityDetails} trigger={visibilityReasonsInner} />
              </Show>
              <Show when={showDataExtractionIndicator}>
                <FeatureIndicatorIcon
                  isInteractive
                  path={mdiAutoFix}
                  content={'This block was created automatically, based on data extraction document'}
                  data-testid="block-icon-indicator:extraction-candidate"
                  onClick={handleExtractionCandidateClick}
                />
              </Show>
              <Show when={showVariationsIndicator}>
                <FeatureIndicatorIcon
                  path={hasVariationsConditionalLogic ? mdiArrowDecisionAuto : mdiPaletteSwatchVariant}
                  content={`${variations.count} variations`}
                  data-testid="block-icon-indicator:variations"
                />
              </Show>
              <Show when={showPageBreakIndicator}>
                <FeatureIndicatorIcon
                  path={mdiFormatPageBreak}
                  content={`Page break after block`}
                  data-testid="block-icon-indicator:page-break"
                />
              </Show>
              <Show when={showUsedInTemplatesControls}>
                <BlockUsedWithin block={block} />
              </Show>
              <Show when={showPermissionsSetIndicator}>
                <FeatureIndicatorIcon
                  path={mdiTuneVertical}
                  content={<BlockPermissionsSet block={block} />}
                  data-testid="block-icon-indicator:permissions-set"
                />
              </Show>
              <Show when={showLockedIndicator}>
                <FeatureIndicatorIcon
                  path={mdiLock}
                  content={getLockedBlockPermissionsMessage(
                    block.canEdit,
                    block.canDelete,
                    block.canEditOnTemplate,
                    block.canDeleteOnTemplate,
                    isTemplate,
                  )}
                  data-testid={`block-icon-indicator:${block.canDelete ? 'lock-edit' : 'lock-delete'}`}
                />
              </Show>
            </Show>
          </div>
          <Show when={showBlockAlerts}>
            <BlockAlert block={block} hasBeenMovedByRenewal={hasBeenMovedByRenewal} />
          </Show>
          <Show when={showCreator}>
            <BlockCreatorController id={id} order={order} sectionId={section_id} />
          </Show>
        </Show>
        <Show when={renderPageBreak}>
          <hr />
        </Show>
      </div>
    </BlockFlagsProvider>
  );
};
