import { type FC } from 'react';
import { Controller } from 'react-hook-form';
import InputCheckbox from '@components/InputCheckbox';
import InputSelect from '@components/InputSelect';
import type { TemplateShort, WithRequired } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';

import { WarningBanner } from '../components/WarningBanner';
import type { StepProps } from '../types';

interface PublishedTemplate extends Omit<WithRequired<TemplateShort, 'name'>, 'status'> {
  status: 'PUBLISHED';
}

const filterPublishedTemplates = (item: TemplateShort): item is PublishedTemplate => {
  return !isEmptyValue(item.name) && item.status === 'PUBLISHED';
};

export const Step3: FC<StepProps> = ({ control, formValues, templates }) => {
  const parsedTemplateOptions = templates
    .filter(filterPublishedTemplates)
    .map((item) => ({ name: item.name, value: item.id }));

  const isRefresh = formValues.type === 'refresh';

  return (
    <>
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            {...field}
            errors={error?.message}
            isDisabled={field.disabled || isRefresh}
            menuOuterClassName="max-w-[32rem]"
            labelText="Select template"
            options={parsedTemplateOptions}
            placeholder="Select template"
            // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
            value={formValues.baseTemplateId}
          />
        )}
        name="baseTemplateId"
      />
      <WarningBanner formValues={formValues} />
      <div className="flex w-full flex-col gap-y-2">
        <p className="text-sm font-medium leading-5 text-info-700">Additional block content options</p>
        <div className="flex flex-col divide-y divide-gray-200 rounded-md border border-gray-200">
          <Controller
            name="skipPreviouslyRemovedBlocks"
            control={control}
            render={({ field }) => (
              <InputCheckbox
                className="p-4 text-sm"
                isChecked={field.value}
                onClick={() => field.onChange(!field.value)}
                labelText="Skip previously removed blocks or clauses"
              />
            )}
          />
          <Controller
            name="keepPreviouslyAddedBlocks"
            control={control}
            render={({ field }) => (
              <InputCheckbox
                className="p-4 text-sm"
                isChecked={field.value}
                onClick={() => field.onChange(!field.value)}
                labelText="Keep any additional blocks or clauses added"
              />
            )}
          />
        </div>
      </div>
      <WarningBanner formValues={formValues} type="skipPreviouslyRemovedBlocks" />
      <WarningBanner formValues={formValues} type="keepPreviouslyAddedBlocks" />
    </>
  );
};
