import { type FC, useEffect, useMemo } from 'react';
import Button from '@components/Button';
import { DividerWithText } from '@components/DividerWithText';
import IconMdi from '@components/IconMdi';
import InputSelect from '@components/InputSelect';
import { Show } from '@components/Show';
import type { StateFromBlock } from '@ContractBuilder/modules/block-edit';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { mdiCloseCircle, mdiPlus } from '@mdi/js';
import type { BlockVariation } from '@root/@types/database';
import type { BlockVisibilityCondition, BlockVisibilityValueType, UIOnChangeFn } from '@root/@types/types';
import { isDefaultVariation } from '@root/helpers/variations';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { cloneDeep, get, set, unset } from 'lodash-es';

import { modalTabsClasses } from '../classes';
import { BlockVisibilityRow } from '../components/BlockVisibilityRow';
import { EMPTY_CONDITION_STATE, MODE_OPTIONS, VARIATION_MODE_OPTIONS } from '../constants';
import { useBlockVisibilityForm } from '../controllers/context';
import type { VisibilityConfigPath } from '../types';
import { buildRowKey, getPathDetails } from '../utils';

const buttonClasses = cva(['border-none', 'shadow-none', 'text-xs']);

export const BlockVisibilityFormView: FC = () => {
  const {
    basePath,
    formValues,
    handleAdd,
    handleRemove,
    handleRemoveAll,
    hasEmptyConditions,
    isDisabled,
    isReadOnly,
    setFormValues,
    setTab,
    tab,
  } = useBlockVisibilityForm();
  const { onChange: onDatapointsChange, setFormValues: setDatapoints } = useDatapointsStore(
    ({ onChange, setFormValues }) => ({ onChange, setFormValues }),
  );

  const isNotEditable = isReadOnly || isDisabled;
  const variations: BlockVariation[] = formValues?.variations ?? [];
  const hasVariations = variations?.length > 0;
  const setTabId = (id: string) => () => setTab(id);

  const modePath = useMemo(() => basePath + '.mode', [basePath]);
  const conditionsPath = useMemo(() => basePath + '.conditions', [basePath]);

  const conditions: BlockVisibilityCondition[] = useMemo(
    () => get(formValues, conditionsPath),
    [conditionsPath, formValues],
  );

  const mode = useMemo(() => get(formValues, modePath), [modePath, formValues]);

  useEffect(() => {
    setDatapoints(formValues);
    // eslint-disable-next-line -- Run only when either `conditionsPath` or conditions' length changes
  }, [conditionsPath, conditions?.length]);

  const handleChange = (value: BlockVisibilityValueType | undefined, name: VisibilityConfigPath) => {
    const { isValue, operatorPath, shouldClearValue, valuePath } = getPathDetails(name, value);
    onDatapointsChange(undefined, valuePath);

    if (isValue) {
      onDatapointsChange(value, name);
    }

    return setFormValues((current) => {
      if (!current) {
        return undefined;
      }
      const obj: StateFromBlock = cloneDeep(current);

      if (shouldClearValue) {
        unset(obj, valuePath);
        set(obj, operatorPath, EMPTY_CONDITION_STATE.operator);
      }

      set(obj, name, value);

      return obj;
    });
  };

  return (
    <div className="mx-auto min-h-[350px] w-full pr-4" id="block-visibility-form">
      <Show when={hasVariations}>
        <div className="mb-3 flex border-b">
          {variations?.map(({ id, name }, idx) =>
            isDefaultVariation(id) ? null : (
              <button
                key={id}
                onClick={setTabId(id)}
                className={modalTabsClasses({ isTabOpen: tab === id, className: idx === 0 && 'border-l' })}
                type="button"
              >
                {name}
              </button>
            ),
          )}
        </div>
      </Show>
      <div className={clsx('flex', 'flex-col', 'gap-5', 'relative')}>
        {conditions?.length ? (
          <>
            <DividerWithText id="visibility-mode">
              <InputSelect
                options={hasVariations ? VARIATION_MODE_OPTIONS : MODE_OPTIONS}
                name={modePath}
                className={hasVariations ? 'min-w-fit' : 'w-48'}
                isSearchable={false}
                onChange={handleChange as UIOnChangeFn}
                data-testid="hide-show"
                value={mode}
                isDisabled={hasVariations || isDisabled}
                isReadOnly={isReadOnly}
                aria-label="Select visibility mode"
              />
            </DividerWithText>
            {conditions.map((condition, index) => (
              <BlockVisibilityRow
                condition={condition}
                data-testid="datapoint"
                idx={index}
                key={buildRowKey(tab, condition, index, conditions)}
                onChange={handleChange}
                onDelete={() => handleRemove(index)}
              />
            ))}

            <div className="mb-3 flex w-full justify-between">
              <Button
                id="add-button"
                data-testid="new-condition"
                onClick={handleAdd}
                kind="ghost"
                className={buttonClasses({ className: 'text-primary-500' })}
                isDisabled={isNotEditable || hasEmptyConditions}
              >
                <IconMdi path={mdiPlus} />
                Add a new condition
              </Button>
              <Button
                id="add-button"
                data-testid="remove-all-conditions"
                onClick={handleRemoveAll}
                size="sm"
                className={buttonClasses({ className: 'text-info-500' })}
                isDisabled={isNotEditable}
              >
                Remove all conditions
                <IconMdi path={mdiCloseCircle} />
              </Button>
            </div>
          </>
        ) : (
          <div className="mt-16 flex flex-col items-center gap-3 text-sm">
            No conditions yet
            <Button
              id="add-button"
              onClick={handleAdd}
              kind="ghost"
              className={buttonClasses({ className: 'text-primary-500' })}
              isDisabled={isNotEditable || hasEmptyConditions}
            >
              <IconMdi path={mdiPlus} />
              Add a new condition
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
