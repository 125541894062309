import { ERROR_BRANDING_NOT_FOUND } from '@features/branding-edit/constants';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { BrandingDetailsType } from '@root/@types/types';
import axios from 'axios';

import { normaliseBranding } from '../utils';

export const fetchBrandingDetails = async (brandingId: string): Promise<BrandingDetailsType> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/brandings/' + brandingId,
  });

  const { data } = await axios(networkPayload);

  if (!data) {
    throw new Error(ERROR_BRANDING_NOT_FOUND);
  }

  return normaliseBranding(data);
};
