import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import InputText from '@components/InputText';

import type { StepProps } from '../types';

export const Step1: FC<StepProps> = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <InputText
            {...field}
            errors={error?.message}
            isDisabled={field.disabled}
            labelText="Renewal name"
            placeholder=""
          />
        )}
      />
    </>
  );
};
