import { useCallback, useState } from 'react';

import { Step } from '../types';

interface UseStepperArgs {
  onCancel: () => void;
}

export const useStepper = ({ onCancel }: UseStepperArgs) => {
  const [currentStep, setCurrentStep] = useState(Step.Base);

  const getStepperItemState = useCallback(
    (step: Step) => {
      const maxActiveStep = 3;

      if (step === Math.min(maxActiveStep, currentStep)) {
        return { isActive: true };
      }

      if (step < currentStep) {
        return { isCompleted: true };
      }

      return {};
    },
    [currentStep],
  );

  const onPrevious = useCallback(
    () =>
      setCurrentStep((current) => {
        if (current === 2) {
          return 1;
        }

        if (current === 3) {
          return 2;
        }

        onCancel();
        return current;
      }),
    [onCancel],
  );

  const onNext = useCallback(
    () =>
      setCurrentStep((current) => {
        return Math.min(current + 1, 3) as Step;
      }),
    [],
  );

  return { currentStep, getStepperItemState, onNext, onPrevious };
};
