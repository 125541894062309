import type { FC, PropsWithChildren } from 'react';
import { Children, cloneElement } from 'react';
import { useSchemaStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import type { FormError } from '@root/@types/types';
import schema from '@root/helpers/schema/schema-cdr.json';
import clsx from 'clsx';
import { cloneDeep, get, set } from 'lodash-es';

import { useStickyState } from '../hooks/useStickyState';

import Icon from './Icon';

const Dot = ({ value, className }: { value?: any; className?: string }) => (
  <div
    className={clsx(
      'text-semibold ml-1 flex w-4 items-center justify-center rounded text-white',
      value ? 'bg-error-900' : 'bg-success-900',
      className,
    )}
    style={{ fontSize: '10px' }}
  >
    {value}
  </div>
);

const jsonClasses = ['rounded-md', 'bg-info-100', 'p-3', 'text-info-900'];

interface TabProps {
  id: string;
  isSelected?: boolean;
  render: any;
  value: any;
  onClick?: any;
}

const Tab: FC<TabProps> = ({ id, isSelected, value, onClick }) => (
  <div
    className={clsx('-my-px flex cursor-pointer px-3 py-2', isSelected && 'border-b-2 border-primary-500')}
    onClick={onClick}
  >
    {id} {(value || value === 0) && <Dot className={'w-4'} value={value} />}
  </div>
);

interface TabsProps {
  size: DebuggerSize;
}

const Tabs: FC<PropsWithChildren<TabsProps>> = ({ children, size }) => {
  const first = Children.toArray(children).map((child: any) => child.props.id)[0];
  const [selectedTab, setSelectedTab] = useStickyState(first, 'debuggerTabs');
  const selectedChild: any =
    Children.toArray(children).find((c: any) => selectedTab === c?.props?.id) || Children.toArray(children)?.[0];
  const render = selectedChild?.props?.render;
  const isFn = render && render instanceof Function;

  return (
    <div className="text-xs ">
      <div className="border-b border-info-500 font-semibold">
        <div className="flex bg-info-200">
          {Children.toArray(children).map((child: any) => {
            const isSelected = selectedTab === child.props.id;

            return cloneElement(child, {
              ...child.props,
              isSelected,
              key: child.props.id,
              onClick: () => setSelectedTab(child.props.id),
            });
          })}
        </div>
      </div>
      <div
        className="relative overflow-y-scroll p-4"
        style={{ height: (size === DebuggerSize.default && '50vh') || '80vh' }}
      >
        {isFn ? render() : render}
      </div>
    </div>
  );
};

interface Props {
  handleClose: any;
}

enum DebuggerSize {
  default = 'default',
  maximized = 'maximized',
}

const Debugger: FC<Props> = ({ handleClose }) => {
  const { schemaTreeList, schemaValidation, validate } = useSchemaStore(
    ({ schemaTreeList, validate, schemaValidation }) => ({
      schemaTreeList,
      schemaValidation,
      validate,
    }),
  );

  const { formValues, setFormValues } = useDatapointsStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));

  const [size, setSize] = useStickyState(DebuggerSize.default, 'debuggerSize');
  const mappedValidationErrors = Object.keys(schemaValidation)?.map((key: string) => ({
    [key]: schemaValidation[key].errors?.map(({ instancePath, message }: FormError) => {
      return { [instancePath?.split('/')?.pop() as string]: message };
    }),
  }));

  const handleFillForm = (formValuesUpToDate: any) => {
    const nextFormValues = cloneDeep(formValuesUpToDate);
    const fieldsWithResolvedRefs = schemaTreeList?.map((field) => {
      if (field.$ref) {
        const ref = field.$ref.replace('#/definitions/', '');
        const definitions = schema?.definitions as any;
        const resolvedField = definitions[ref]?.enum;
        return { ...field, enum: resolvedField };
      }
      return field;
    });

    fieldsWithResolvedRefs?.forEach((field) => {
      let value;
      if (field.type === 'string') {
        if (field.format === 'date') {
          value = '01/01/2023';
        } else if (field.format === 'date-time') {
          value = new Date().toISOString();
        } else if (field.enum) {
          value = field.enum[0];
        } else {
          value = 'Lorem ipsum';
        }
      } else if (field.type === 'number') {
        value = 1;
      } else if (field.type === 'array') {
        value = [get(field, ['items', 'enum', '0'])].filter(Boolean);
      }
      set(nextFormValues, field.id, value);
    });
    setFormValues(nextFormValues);
    useSchemaStore.getState().updateSchemaStore(nextFormValues);
  };

  return (
    <div
      className={clsx(
        'fixed bottom-0 right-8 z-20 rounded-t border border-info-500 bg-white pb-4 shadow-lg',
        size === DebuggerSize.default && 'w-1/3',
        size === DebuggerSize.maximized && 'w-1/2',
      )}
    >
      <div>
        <Tabs size={size}>
          <Tab
            id="Form"
            value={mappedValidationErrors.length}
            render={
              <pre className="mb-6 whitespace-pre-wrap">
                <button className="mb-4 rounded-md border p-2" onClick={() => handleFillForm(formValues)}>
                  Fill form values
                </button>
                <button className="mb-4 rounded-md border p-2" onClick={() => validate()}>
                  Validate
                </button>
                <p className="mb-4 font-bold">Form values</p>
                <div className="mb-4">
                  <pre className={clsx(jsonClasses)}>{JSON.stringify(formValues, null, 2)}</pre>
                </div>

                <p className="mb-4 font-bold">Validation errors</p>
                <div className="mb-4">
                  <pre className={clsx(jsonClasses)}>{JSON.stringify(mappedValidationErrors, null, 2)}</pre>
                </div>
              </pre>
            }
          />
        </Tabs>

        {size === DebuggerSize.default && (
          <Icon
            name="unfold-more"
            className="absolute right-6 top-0 m-3 w-3 cursor-pointer fill-current text-info-800"
            onClick={() => setSize(DebuggerSize.maximized)}
          />
        )}

        {size === DebuggerSize.maximized && (
          <Icon
            name="unfold-less"
            className="absolute right-6 top-0 m-3 w-3 cursor-pointer fill-current text-info-800"
            onClick={() => setSize(DebuggerSize.default)}
          />
        )}

        <Icon
          name="cross"
          className="absolute right-0 top-0 m-3 w-3 cursor-pointer fill-current text-info-800"
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default Debugger;
