import type { FC } from 'react';
import { InlineEditableText } from '@components/InlineEditableText';
import InputSelect from '@components/InputSelect';
import Tooltip from '@components/Tooltip';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import { EntityField } from '@ContractBuilder/contract-builder-header/constants';
import type { OnSaveFn } from '@ContractBuilder/contract-builder-header/modules/entity-details/hooks/use-editable-field';
import type { EntityData } from '@ContractBuilder/types';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import type { UIInputValue } from '@root/@types/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';

import { useEditableField } from '../hooks';

interface EditableClassOfBusinessFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn;
}

export const EditableClassOfBusinessField: FC<EditableClassOfBusinessFieldProps> = ({
  entity,
  hasOperationsDisabled,
  onSave,
}) => {
  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField({
    defaultValue: entity.class_of_business,
    name: EntityField.ClassOfBusiness,
    onSave,
  });

  return (
    <div className="flex items-center gap-1">
      <Tooltip content="Class of Business">
        <span>
          <LocationMarkerIcon className="h-4 w-4" />
        </span>
      </Tooltip>
      <InlineEditableText
        editor={
          <InputSelect
            value={state.value as UIInputValue}
            options={ALL_CLASSES_OF_BUSINESS.map((label) => ({ name: label, value: label }))}
            onChange={(value) => onChange(value ?? '')}
            name={EntityField.ClassOfBusiness}
            isDisabled={false}
            size="sm"
            menuOuterClassName="min-w-fit"
          />
        }
        editMode={state.isEditing}
        disabled={hasOperationsDisabled}
        display={entity.class_of_business || 'No class set'}
        onClick={onClick}
        onCancel={onCancel}
        onConfirm={onUpdate}
        tooltipContent={!hasOperationsDisabled ? 'Edit class of business' : undefined}
        withEditIcon={false}
        className={responsiveTruncateClasses([
          'rounded border border-transparent px-1 py-0.5 ',
          !hasOperationsDisabled ? 'hover:border-gray-300' : '',
        ])}
      />
    </div>
  );
};
