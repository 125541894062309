import type { FC } from 'react';
import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Form from '@components/Form';
import Modal from '@components/Modal';
import { Show, ShowFirstMatching } from '@components/Show';
import { Stepper } from '@components/Stepper';
import { useEntityStore } from '@ContractBuilder/store';
import { useRenewalStore } from '@ContractBuilder/store/renewal.store';
import { createRenewal } from '@mutations/createRenewal';
import Nav from '@src/routes';
import clsx from 'clsx';
import { omit } from 'lodash-es';

import { useCreateRenewalForm } from '../hooks/useCreateRenewalForm';
import { useStepper } from '../hooks/useStepper';
import { useTemplatesData } from '../hooks/useTemplatesData';
import type { CreateRenewalFormState, StepProps } from '../types';
import { Step } from '../types';
import { FormFooter } from '../views/FormFooter';
import { LoadingSkeleton } from '../views/LoadingSkeleton';
import { Step1 } from '../views/Step1';
import { Step2 } from '../views/Step2';
import { Step3 } from '../views/Step3';

interface CreateRenewalModalControllerProps {
  handleClose: () => void;
}

export const CreateRenewalModalController: FC<CreateRenewalModalControllerProps> = ({ handleClose }) => {
  const navigate = useNavigate();
  const submission = useEntityStore(({ submission }) => submission);
  const { setRenewalId, setMovedBlockIds } = useRenewalStore(({ setRenewalId, setMovedBlockIds }) => ({
    setRenewalId,
    setMovedBlockIds,
  }));

  const { templates, canRefreshCurrentTemplate, isFetchingTemplates } = useTemplatesData(submission);
  const { control, formValues, handleSubmit } = useCreateRenewalForm(
    submission?.name,
    submission?.sourceResourceId,
    canRefreshCurrentTemplate,
  );

  const [isLoading, setIsLoading] = useState(false);
  const { currentStep, getStepperItemState, onNext, onPrevious } = useStepper({ onCancel: handleClose });

  const submissionId = useParams().id;

  const handleCreateRenewal = async (values: CreateRenewalFormState) => {
    setIsLoading(true);

    try {
      const { id: renewalId, movedBlockIds } = await createRenewal({
        ...omit(values, 'type'),
        renewFromSubmissionId: submissionId as string,
      });

      const renewalContractPath = generatePath(Nav.SubmissionContract, {
        id: renewalId,
      });

      setRenewalId(renewalId);
      setMovedBlockIds(movedBlockIds);

      return navigate(renewalContractPath);
    } finally {
      setIsLoading(false);
    }
  };

  const stepProps: StepProps = {
    canRefreshCurrentTemplate,
    control,
    formValues,
    isLoading,
    submission,
    step: currentStep,
    templates,
  };

  const isFirstStep = currentStep === Step.Base;
  const isSecondStep = currentStep === Step.Type;

  return (
    <Modal
      className="w-[32rem]"
      onClose={handleClose}
      open
      shouldShowCloseIcon={false}
      title="Create a renewal contract"
      titleClassName="pt-4 text-lg leading-6 font-medium !justify-center"
    >
      <Form
        className={clsx('flex', 'flex-col', 'items-center justify-center', 'gap-6', 'px-8 pb-6 pt-0')}
        onSubmit={handleSubmit(handleCreateRenewal)}
      >
        <p className="text-sm font-normal leading-5 text-gray-500">
          Please fill in the following information to setup a renewal
        </p>
        <Stepper getStepperItemState={getStepperItemState} size={3} />
        <ShowFirstMatching>
          <Show when={isFetchingTemplates}>
            <LoadingSkeleton />
          </Show>
          <Show when={true}>
            <>
              <ShowFirstMatching>
                <Show when={isFirstStep}>
                  <Step1 {...stepProps} />
                </Show>
                <Show when={isSecondStep}>
                  <Step2 {...stepProps} />
                </Show>
                <Show when={true}>
                  <Step3 {...stepProps} />
                </Show>
              </ShowFirstMatching>
              <FormFooter {...stepProps} onClose={handleClose} onNext={onNext} onPrevious={onPrevious} />
            </>
          </Show>
        </ShowFirstMatching>
      </Form>
    </Modal>
  );
};
