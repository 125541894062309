import subDivisions from '@root/helpers/schema/country_subdivisions.json';
import type { KeywordDefinition } from 'ajv';

export const ensureSubDivisionBelongsToCountry: KeywordDefinition = {
  keyword: 'ensureSubDivisionBelongsToCountry',
  type: 'object',
  validate: (_: any, data: {}) => {
    const subDivisionKey = Object.keys(data).find((key) => key.endsWith('sub_division')) ?? '';
    const countryKey = subDivisionKey.substring(0, subDivisionKey.indexOf('_sub_division'));
    // @ts-expect-error
    const subDivisionValue = data?.[subDivisionKey];
    if (!subDivisionValue) {
      return true;
    }
    // @ts-expect-error
    const countryValue = data?.[countryKey];
    // @ts-expect-error
    const allowedSubDivisions = subDivisions?.[countryValue]?.enum ?? [];

    return allowedSubDivisions.includes(subDivisionValue);
  },
  error: {
    message: 'must be equal to one of the allowed values',
  },
};
