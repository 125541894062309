import type { ResolvedSchemaField, ResolvedSchemaTree } from './types';

export const sortByTitle = (
  a: ResolvedSchemaField | ResolvedSchemaTree,
  b: ResolvedSchemaField | ResolvedSchemaTree,
) => {
  if (!a.title) {
    return 1;
  }

  if (!b.title) {
    return -1;
  }

  return a.title.localeCompare(b.title);
};
