import type { FormError } from '@root/@types/types';

type ErrorType = FormError[] | null;

const toOwnKey = (error: FormError) => {
  const path = error.instancePath.split('/').splice(1);

  let ownKey = path
    .map((key: string) => {
      const numKey = Number(key);
      return Number.isNaN(numKey) ? key : `[${key}]`;
    })
    .join('.')
    .replaceAll('.[', '[');

  if (error.params?.missingProperty) {
    ownKey += `.${error.params.missingProperty}`;
  }

  return normaliseMessage({ ...error, ownKey });
};

const normaliseMessage = (error: FormError) => {
  switch (true) {
    case ['required', 'isNotEmpty'].includes(error.keyword):
      return {
        ...error,
        message: `Field is required`,
      };
    case error.keyword === 'minLength':
      return {
        ...error,
        message: `Field can't have less than ${error.params?.limit} characters.`,
      };
    case error.keyword === 'maxLength':
      return {
        ...error,
        message: `Field can't have more than ${error.params?.limit} characters`,
      };
    default: {
      return error;
    }
  }
};

const formatErrors = (errors: ErrorType): FormError[] | null => {
  if (!errors?.length) return null;
  return errors.map(toOwnKey).filter(Boolean);
};

export const getFieldError = (errors: FormError[] | null, ownKey: string, componentType?: string): string[] | null => {
  if (!errors?.length) {
    return null;
  }

  const keys = [ownKey];

  if (componentType === 'InputCurrency') {
    keys.push(ownKey + '.amount');
    keys.push(ownKey + '.currency');
  }

  let fieldErrors = errors.filter((error) => keys.includes(error.ownKey)).filter(Boolean);

  if (componentType === 'InputCountrySubDivision') {
    const countrySubDivisionError = errors.find(
      (error: FormError) => ownKey.startsWith(error.ownKey) && error.keyword === 'ensureSubDivisionBelongsToCountry',
    );
    if (countrySubDivisionError) {
      fieldErrors = [...fieldErrors, countrySubDivisionError];
    }
  }

  if (componentType === 'InputMultiSelect') {
    const multiSelectErrors = errors
      .filter((error) => error.ownKey !== ownKey && error.ownKey.startsWith(ownKey))
      .filter(Boolean);
    if (multiSelectErrors.length > 0) {
      fieldErrors = [...fieldErrors, ...multiSelectErrors];
    }
  }

  if (!fieldErrors.length) {
    return null;
  }

  return fieldErrors.map(({ message }) => message ?? '').filter(Boolean);
};

export default formatErrors;
