import { authProvider } from '@helpers/auth';
import type { BaseUser } from '@root/@types/types';
import * as Sentry from '@sentry/browser';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface UserStoreBase {
  setUser: (user: BaseUser | null) => void;
  refreshUser: (updatedAt: string) => void;
  setAuthErrorCode: (errorCode: number) => void;
  clearUser: () => void;
  updatedAt: string;
  authErrorCode: number | null;
}

interface UseUserStoreAuthenticated extends UserStoreBase {
  user: BaseUser;
  isLoggedIn: true;
}

interface UseUserStoreNotAuthenticated extends UserStoreBase {
  user: null;
  isLoggedIn: false;
}

export const useUserStore = create<UseUserStoreAuthenticated | UseUserStoreNotAuthenticated>()(
  persist(
    (set) => ({
      isLoggedIn: false,
      updatedAt: '',
      user: null,
      authErrorCode: null,
      setUser: (user) => {
        if (!user) {
          return set({ user: null, isLoggedIn: false });
        }

        Sentry.setUser({
          id: user.username,
          email: user.email,
        });

        return set({ user, isLoggedIn: true, authErrorCode: null });
      },
      clearUser: () => {
        Sentry.setUser(null);
        return set({ user: null, isLoggedIn: false, updatedAt: '' });
      },
      setAuthErrorCode: (errorCode) => {
        return set({ authErrorCode: errorCode });
      },
      refreshUser: async (updatedAt: string) => {
        const user = await authProvider.getFreshUserInfo();
        if (!user) {
          Sentry.setUser(null);
          return set({ user: null, isLoggedIn: false, updatedAt: '' });
        }
        return set({ user, isLoggedIn: true, updatedAt });
      },
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        user: state.user,
        isLoggedIn: state.isLoggedIn,
        updatedAt: state.updatedAt,
      }),
    },
  ),
);
