import type { FC } from 'react';
import { useQuery } from 'react-query';
import type { Action } from '@root/@types/types';
import { TEMPLATE_STATUSES } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchTags, fetchTemplates } from '@src/queries';

import { MODALS } from '../../constants';

import { getTemplatesTableColumns } from './helpers/columns/getTemplatesTableColumns';
import { ReactTable } from './components';
import { useDataIsEmpty } from './hooks';

export const Templates: FC = () => {
  const { showModal } = useModal();
  const {
    data = [],
    isLoading: isTemplatesLoading,
    status,
  } = useQuery(['templates'], fetchTemplates, { refetchOnWindowFocus: false });
  const { data: tags = [], isLoading: isTagsLoading } = useQuery(['tags'], fetchTags, { refetchOnWindowFocus: false });

  const isEmpty = useDataIsEmpty(data, status);
  const columns = getTemplatesTableColumns({ tags: Array.isArray(tags) ? tags : [] });

  const actions: Action[] = [
    {
      key: 'create',
      label: 'New Template',
      onClick: () => {
        showModal(MODALS.ADMIN_CREATE_TEMPLATE, { data });
      },
    },
  ];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
        class_of_business: false,
        created_at: false,
      }}
      entityName="template"
      filteredData={data}
      initialFilters={[{ id: 'status', value: TEMPLATE_STATUSES.filter((status) => status !== 'ARCHIVED') }]}
      initialSorting={[{ id: 'created_at', desc: true }]}
      status={status}
      isEmpty={isEmpty}
      isLoading={isTemplatesLoading || isTagsLoading}
    />
  );
};

export const UserTemplatesPage = withMenuLayout(Templates);
