import type { FC } from 'react';
import { CursorClickIcon } from '@heroicons/react/outline';
import type { Action } from '@root/@types/types';
import clsx from 'clsx';
import pluralize from 'pluralize';

import { ActionButton } from './ActionButton';

interface TableEmptyStateProps {
  entityName: string;
  action?: Action;
}

export const TableEmptyState: FC<TableEmptyStateProps> = ({ action, entityName }) => {
  return (
    <div className="relative h-full w-full">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 translate-y-1/2">
        <div className={clsx('flex flex-col items-center justify-start gap-6')}>
          <CursorClickIcon height={48} className="text-gray-400" />
          <div className="flex flex-col items-center justify-start gap-2">
            <div className="self-stretch text-center text-xl font-bold leading-[48px] text-gray-700">
              No {pluralize(entityName)} yet
            </div>
            <div className="self-stretch text-center text-base font-normal leading-normal text-gray-500">
              Create a new {entityName} to get started and it will show up here
            </div>
          </div>
          {action && <ActionButton action={action} />}
        </div>
      </div>
    </div>
  );
};
