import { getFormattedStatus } from '@utils/get-formatted-status';
import { cva } from 'class-variance-authority';

export const badgeIconClasses = cva(['h-1.5 w-1.5'], {
  variants: {
    kind: {
      success: 'fill-success-500',
      primary: 'fill-primary-500',
      info: 'fill-info-300',
    },
  },
});

const getBadgeKindByStatus = (status: string) => {
  switch (status) {
    case 'DRAFT':
      return 'success';
    case 'FINAL':
    case 'PUBLISHED':
      return 'primary';
    case 'ARCHIVED':
    default:
      return 'info';
  }
};

const StatusBadge = ({ status }: { status: string }) => (
  <span className="mt-4 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
    <svg className={badgeIconClasses({ kind: getBadgeKindByStatus(status) })} viewBox="0 0 6 6" aria-hidden="true">
      <circle cx={3} cy={3} r={3} />
    </svg>
    {getFormattedStatus(status)}
  </span>
);

export default StatusBadge;
