import { useUserStore } from '@Auth/store';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { DrawerLayoutComponent } from '@ContractBuilder/modules/drawer-layout';
import { applyRules } from '@ContractBuilder/rules/apply-rules';
import type { BlockRulesArgs, BlockRuleSet } from '@ContractBuilder/rules/block';
import {
  blockRulesCommon,
  blockRulesHasBeenMovedByRenewal,
  blockRulesIsCreate,
  blockRulesIsDeletable,
  getVariationsCount,
} from '@ContractBuilder/rules/block';
import { blockRulesIsEditable } from '@ContractBuilder/rules/block/editable';
import { ctxIsPDF } from '@ContractBuilder/rules/block/is-ctx';
import { useEntityStore } from '@ContractBuilder/store';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { useRenewalStore } from '@ContractBuilder/store/renewal.store';
import type { DocumentContext } from '@ContractBuilder/utils/get-document-context';
import type { BlockVisibilityOutput, ResourceBlock } from '@root/@types/types';

import { useDeepCompareMemo } from './use-deep-compare-memo';

export enum BlockScenario {
  Creating = 'CREATING',
  Editing = 'EDITING',
  Unknown = 'UNKNOWN',
  Idle = 'IDLE',
  PDF = 'PDF',
}

export type BlockDetails = {
  hasBeenMovedByRenewal: boolean;
  hasVariationsConditionalLogic: boolean;
  isDeletable: boolean;
  isEditable: boolean;
  isEditing: boolean;
  isParentEntityEditable: boolean;
  isPreview: boolean;
  isSummaryBlock: boolean;
  isVariationActionRequired: boolean;
  isVisible: boolean;
  renderPageBreak: boolean;
  showBlockActions: boolean;
  showBlockAlerts: boolean;
  showCreator: boolean;
  showDataExtractionIndicator: boolean;
  showLockedIndicator: boolean;
  showPageBreakIndicator: boolean;
  showPermissionsSetIndicator: boolean;
  showUsedInTemplatesControls: boolean;
  showVariationsIndicator: boolean;
  showVisibilityReasonsIndicatorIcon: boolean;
  variations: {
    count: number;
    selected: boolean;
    visibilityDetails: BlockVisibilityOutput[] | undefined;
  };
  visibilityDetails: BlockVisibilityOutput | undefined;
};

export type GeneratedBlockFlags = { scenario: BlockScenario; details: BlockDetails };

interface BlockModifiers {
  context: DocumentContext;
  disableControls: boolean;
  isEndorsementView: boolean;
}

export const useBlockFlagsGenerator = (
  block: ResourceBlock,
  { context, disableControls, isEndorsementView }: BlockModifiers,
): GeneratedBlockFlags => {
  const submission = useEntityStore(({ submission }) => submission);
  const isRevisionHistory = useDrawerStore(({ state }) => state?.type === DrawerLayoutComponent.RevisionHistory);
  const isEditing = useBlockEditFormStore(({ formValues }) => formValues?.id === block.id);
  const { user } = useUserStore(({ user }) => ({ user }));
  const { movedBlockIds, renewalId } = useRenewalStore(({ movedBlockIds, renewalId }) => ({
    movedBlockIds,
    renewalId,
  }));

  const status = submission?.status;
  const entityId = submission?.id;

  const isCreate = blockRulesIsCreate(block);
  const isEditable = useDeepCompareMemo(
    () =>
      blockRulesIsEditable({
        block,
        context,
        disableControls,
        isEndorsementView,
        isRevisionHistory,
        status,
        user,
      }),
    [block, disableControls, isRevisionHistory, isEndorsementView, status, user, context],
  );
  const isDeletable = useDeepCompareMemo(
    () => blockRulesIsDeletable({ block, isRevisionHistory, status, user, context }),
    [block, isRevisionHistory, status, user, context],
  );

  const hasBeenMovedByRenewal = useDeepCompareMemo(
    () => blockRulesHasBeenMovedByRenewal({ block, entityId, movedBlockIds, renewalId }),
    [block, entityId, movedBlockIds, renewalId],
  );

  const flags = useDeepCompareMemo(
    () =>
      applyRules<BlockRulesArgs, keyof BlockRuleSet>(blockRulesCommon, {
        block,
        context,
        disableControls,
        hasBeenMovedByRenewal,
        isDeletable,
        isEditable,
        isEditing,
        isEndorsementView,
        status,
        user,
      }),
    [
      block,
      context,
      disableControls,
      hasBeenMovedByRenewal,
      isDeletable,
      isEditable,
      isEditing,
      isEndorsementView,
      status,
      user,
    ],
  );

  return {
    scenario: isCreate
      ? BlockScenario.Creating
      : isEditing
      ? BlockScenario.Editing
      : ctxIsPDF(context)
      ? BlockScenario.PDF
      : BlockScenario.Unknown,
    details: {
      variations: {
        count: getVariationsCount(block),
        selected: !!block?.selectedVariationId,
        visibilityDetails: block.visibilityComputed?.variations,
      },
      hasBeenMovedByRenewal,
      isDeletable,
      isEditable,
      isEditing,
      visibilityDetails: block.visibilityComputed?.block,
      ...flags,
    },
  };
};
