import type { FC } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUnmount } from 'react-use';
import { ProErrorPage } from '@features/error/views/ProErrorPage';
import Nav from '@src/routes';

import type { DocType, ErrorPageProps, ErrorType } from '../types';
import { PPLErrorPage } from '../views/PPLErrorPage';

const client: string = import.meta.env?.VITE_CLIENT ?? process?.env?.CLIENT;
const isPPL = client === 'ct-ppl';

const HOME = {
  branding: Nav.Branding,
  contract: Nav.Submissions,
  template: Nav.Templates,
};

interface ErrorControllerProps {
  docType?: DocType;
  resetError?: () => void;
  type: ErrorType;
}

export const ErrorController: FC<ErrorControllerProps> = ({ docType, resetError, type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state] = useState<Pick<ErrorControllerProps, 'docType' | 'type'>>(location.state);

  useUnmount(() => navigate(location.pathname, {}));

  const handleRefresh = () => {
    resetError?.();
    window.location.reload();
  };

  const handleNavigateHome = () => {
    const homeURL = HOME[docType ?? 'contract'];
    window.location.href = homeURL;
  };

  const parsedDocType = state?.docType ?? docType;
  const parsedType = state?.type ?? type;

  const errorPageProps: ErrorPageProps = {
    docType: parsedDocType,
    onNavigateHome: handleNavigateHome,
    onRefresh: handleRefresh,
    type: parsedType,
  };

  if (isPPL) {
    return <PPLErrorPage {...errorPageProps} />;
  }

  return <ProErrorPage {...errorPageProps} />;
};
