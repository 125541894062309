import type { FC } from 'react';
import Icon from '@components/Icon';
import { Show, ShowFirstMatching } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { CONTRACT_BUILDER_HEADER_Z_INDEX } from '@constants/z-indices';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import { EditableTemplateBaseField } from '@ContractBuilder/contract-builder-header/modules/entity-details/views/EditableTemplateBaseField';
import type { EntityData } from '@ContractBuilder/types';
import { DocumentTextIcon } from '@heroicons/react/solid';
import { useDocumentLocation, useFeatureFlags } from '@src/hooks';
import clsx from 'clsx';

import { EditableBrandingField } from './EditableBrandingField';
import { EditableClassOfBusinessField } from './EditableClassOfBusinessField';
import { EditableTeamsField } from './EditableTeamsField';

interface EntityDetailsProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: (name: string, value?: string | number[]) => Promise<void>;
  onUpdateBaseTemplate: (sourceTemplateId: string, cb: () => void) => Promise<void>;
}

const getSourceType = (resourceName?: string): string => {
  if (!resourceName) return '';
  return resourceName.startsWith('submission') ? 'Submission' : 'Template';
};

export const EntityDetails: FC<EntityDetailsProps> = ({
  entity,
  hasOperationsDisabled,
  onSave,
  onUpdateBaseTemplate,
}) => {
  const { isTemplate, isSubmission } = useDocumentLocation();

  const getFeatureFlagState = useFeatureFlags();
  const hasEndorsementsFeature = getFeatureFlagState('Endorsements');

  const sourceType = getSourceType(entity.sourceResourceName);
  const sourceName = entity.sourceResourceDisplayName;

  return (
    <div
      className={clsx('ml-9 flex flex-wrap items-center gap-1 text-sm text-gray-600', CONTRACT_BUILDER_HEADER_Z_INDEX)}
    >
      <Show when={!!entity.ref}>
        <Tooltip content={`${isTemplate ? 'Template' : 'Contract'} ID`}>
          <span>
            <DocumentTextIcon className="h-4 w-4" />
          </span>
        </Tooltip>
        <p className={responsiveTruncateClasses('mr-3 cursor-default')}>{entity.ref}</p>
      </Show>
      <Show when={!isTemplate}>
        <Tooltip content={sourceType}>
          <span>
            <Icon
              name="layout"
              className="h-[1.15rem] w-[1.15rem] pt-[0.025rem] text-transparent [&>svg>path]:fill-gray-600 [&>svg]:h-[1.15rem] [&>svg]:w-[1.15rem]"
            />
          </span>
        </Tooltip>
        <ShowFirstMatching>
          <Show when={isSubmission && hasEndorsementsFeature}>
            <EditableTemplateBaseField
              entity={entity}
              hasOperationsDisabled={hasOperationsDisabled}
              onSave={onUpdateBaseTemplate}
            />
          </Show>
          <Show when={true}>
            <p className={responsiveTruncateClasses('mr-3 cursor-default')}>{sourceName}</p>
          </Show>
        </ShowFirstMatching>
      </Show>

      <EditableBrandingField entity={entity} hasOperationsDisabled={hasOperationsDisabled} onSave={onSave} />
      <EditableClassOfBusinessField entity={entity} hasOperationsDisabled={hasOperationsDisabled} onSave={onSave} />
      <EditableTeamsField entity={entity} hasOperationsDisabled={hasOperationsDisabled} onSave={onSave} />
    </div>
  );
};
