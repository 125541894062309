import { toast } from 'react-toastify';
import type { EntityData } from '@ContractBuilder/types';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

export const fetchSubmissions = async (): Promise<EntityData[]> => {
  let data: any[] = [];
  let requestCount = 0;
  const maxRequests = 40;

  return await (async function doFetch(cursor?: string): Promise<EntityData[]> {
    // safeguard against infinite loops caused by API bugs (could affect billing)
    if (requestCount >= maxRequests) {
      toast.error('Maximum request limit reached', {
        autoClose: false,
      });
      return [];
    }

    requestCount++;

    const networkPayload = await getRequestAuthPayload({
      method: 'get',
      url: '/submissions-spa-al',
      params: { cursor },
    });

    const response = await axios(networkPayload);
    data = [...data, ...response.data.data];
    if (response.data.nextCursor) {
      return await doFetch(response.data.nextCursor);
    }

    return data;
  })();
};
