import { z } from 'zod';

import type { PK } from './database.schema';
import { baseDatabaseSchema, userSchema } from './database.schema';

export const CLAUSE_TYPES: readonly [string, ...string[]] = [
  'Condition',
  'Endorsement',
  'Exclusion',
  'Express Warranties',
  'Standard Warranties',
  'Wording',
];

export const BlockVisibilityConfigSchema = z
  .object({
    conditions: z.array(
      z.object({
        field: z.string(),
        operator: z.enum(['IS', 'IS NOT', 'IS EMPTY', 'IS NOT EMPTY']),
        value: z.union([z.string(), z.array(z.string()), z.number(), z.boolean()]).optional(), // is this correct?
      }),
    ),
    match: z.enum(['AND', 'OR']).optional(),
    mode: z.enum(['SHOW', 'HIDE', 'AUTOSELECT']),
  })
  .strict();

export const BlockVariationVisibilityConfigSchema = BlockVisibilityConfigSchema.extend({
  id: z.string(),
}).strict();

export const LibraryBlockSchema = z.object({
  // required fields
  name: z.string(),
  // defaulted fields
  content: z.string().default(''),
  type: z.enum(['clause', 'generic', 'mrc-heading']).default('mrc-heading'),
  canEditOnTemplate: z.boolean().default(true),
  canEdit: z.boolean().default(true),
  canDelete: z.boolean().default(true),
  shouldInsertPageBreak: z.boolean().default(false),
  linkedDatapoints: z.array(z.object({ id: z.string(), isRequired: z.boolean() })).default([]),
  repeaterIds: z.array(z.string()).default([]),
  variations: z
    .array(
      z.object({
        id: z.string(),
        title: z.string(),
        content: z.string(),
        linkedDatapoints: z.array(z.object({ id: z.string(), isRequired: z.boolean() })),
        repeaterIds: z.array(z.string()),
        // to be removed
        name: z.string().optional(), // why do we need this?
        isComplete: z.boolean().optional(), // what is this?
        created_by: userSchema.optional(), // why do we need this?
      }),
    )
    .default([]),
  // optional fields
  seedId: z.string().uuid().optional(),
  parentBlockId: z.string().optional(),
  deleted_at: z.string().nullable().optional(),
  title: z.string().optional(),
  clause_reference: z.string().optional(),
  clause_type: z.enum(CLAUSE_TYPES).optional(),
  helperText: z.string().optional(),
  usedInTemplates: z.array(z.object({ id: z.string(), name: z.string().optional() })).optional(),
  // to be removed
  systemHelperText: z.array(z.string()).optional(), // what is this?
  order: z.number().optional(),
  mrc_heading_type: z.string().optional(),
  copied_from_template_id: z.string().optional(),
  isVisible: z.boolean().optional(),
  isComplete: z.boolean().optional(), // what is this?
  // class_of_business: z.string().optional(), // what is this?
  // commentsCount: z.number().optional(),
});

export const TemplateBlockSchema = z.object({
  // required fields
  section_id: z.string(),
  blockLibraryId: z.string(),
  // optional fields
  // shouldInsertPageBreak: z.boolean().default(false), // TODO: should be here
  canDeleteOnTemplate: z.boolean().default(true),
  selectedVariationId: z.union([z.string().uuid(), z.literal('@@default@@')]).optional(),
  visibility: z
    .object({
      block: BlockVisibilityConfigSchema.optional(),
      variations: z.array(z.union([BlockVariationVisibilityConfigSchema, z.null()])).optional(),
    })
    .optional(),
});

export const SubmissionBlockSchema = LibraryBlockSchema.merge(TemplateBlockSchema)
  .extend({
    extractionCandidateId: z.string().optional(), // do we need this?
  })
  .partial({ blockLibraryId: true });
export const DbLibraryBlockSchema = LibraryBlockSchema.merge(baseDatabaseSchema);
export const DbTemplateBlockSchema = TemplateBlockSchema.merge(baseDatabaseSchema);
export const DbSubmissionBlockSchema = SubmissionBlockSchema.merge(baseDatabaseSchema);

export type DbLibraryBlock = z.infer<typeof DbLibraryBlockSchema> & PK;
export type DbTemplateBlock = z.infer<typeof DbTemplateBlockSchema> & PK;
export type DbSubmissionBlock = z.infer<typeof DbSubmissionBlockSchema> & PK;
export type DbContractBlock = DbTemplateBlock | DbSubmissionBlock;
