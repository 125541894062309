import type { AxiosError } from 'axios';
import axios from 'axios';
import { get } from 'lodash-es';

export const extractBrandingError = (error: string | AxiosError | Error): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (axios.isAxiosError(error)) {
    return get(error, 'response.data.message', 'Not Found');
  }

  if ('message' in error) {
    return error.message;
  }

  return JSON.stringify(error);
};
