import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import type { TagShort } from '@root/@types/types';
import { createTag, deleteTag, updateTag } from '@src/mutations';

import InputText from '../../../../components/InputText';
import Modal from '../../../../components/Modal';

const TagModal = ({
  tag = {
    id: '',
    label: '',
    created_at: '',
  },
  handleClose,
  isAdd,
}: {
  tag: TagShort;
  handleClose: () => void;
  isAdd: boolean;
}) => {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState(tag);

  const handleChangeTagLabel = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, label: target.value });

  const handleSubmit = async () => {
    try {
      handleClose();
      const { id, label } = formValues;
      if (isAdd) {
        await createTag({ label });
      } else {
        await updateTag(id, { label });
      }
      await queryClient.invalidateQueries({ queryKey: ['tags'] });
    } catch (_error) {
      // Swallow the error for now
    }
  };

  const removeTag = async () => {
    try {
      handleClose();
      await deleteTag(tag.id);
      await queryClient.invalidateQueries({ queryKey: ['tags'] });
    } catch (_error) {
      // Swallow the error for now
    }
  };

  return (
    <Modal open className="w-full sm:w-5/6 lg:w-1/2" onClose={handleClose} title={isAdd ? 'Create tag' : 'Edit tag'}>
      <InputText labelText="Label" maxLength={50} isRequired value={formValues.label} onChange={handleChangeTagLabel} />
      {isAdd ? (
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            disabled={!formValues.label}
            onClick={handleSubmit}
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  sm:ml-3 sm:w-auto sm:text-sm"
          >
            Create
          </button>
          <button
            onClick={handleClose}
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-info-300 bg-white px-4 py-2 text-base font-medium text-info-700 shadow-sm hover:bg-info-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="flex flex-row">
          <div className="w-1/2 basis-1/2">
            <div className="mt-5 sm:mt-4 sm:flex">
              <button
                onClick={removeTag}
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-error-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-error-700 focus:outline-none focus:ring-2 focus:ring-error-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
              >
                Delete
              </button>
            </div>
          </div>
          <div className="w-1/2 basis-1/2">
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                onClick={handleSubmit}
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                onClick={handleClose}
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-info-300 bg-white px-4 py-2 text-base font-medium text-info-700 shadow-sm hover:bg-info-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TagModal;
