import type { ChangeEventHandler, FC } from 'react';
import React, { useEffect, useState } from 'react';
import IconMdi from '@components/IconMdi';
import Input from '@components/Input';
import type { InputFieldProps } from '@components/InputField';
import { InputField } from '@components/InputField';
import { Show, ShowFirstMatching } from '@components/Show';
import { Spinner } from '@components/Spinner';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { VirtualizedList } from '@components/virtualization';
import type { Option } from '@hooks/use-fuse-search';
import { mdiCloseCircleOutline, mdiMagnify } from '@mdi/js';
import type { UIOnChangeFn, UISelectionType } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import { useFuseSearch } from '@src/hooks';
import sharedClasses from '@utils/shared-classes';
import clsx from 'clsx';

export interface SearchableInputSelectProps extends InputFieldProps {
  'aria-label'?: string;
  'aria-labelledby'?: string;
  'data-testid'?: string;
  id?: string;
  initialSelectedItem?: string;
  isLoading?: boolean;
  labelText?: string;
  name: string;
  noOptionsLeftLabel?: string;
  onChange: UIOnChangeFn;
  options: UISelectionType[];
  placeholder?: string;
  value?: string;
}

const INNER_CONTAINER_HEIGHT_PX = 398;

export const SearchableInputSelect: FC<SearchableInputSelectProps> = ({
  isDisabled,
  isLoading = false,
  name,
  noOptionsLeftLabel = 'No existing options available',
  onChange,
  options,
  placeholder,
  value,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');

  const { onSearch, searchResult } = useFuseSearch({
    options: options.map(({ name, value }) => ({ label: name, value: value as string })),
    searchText,
  });

  useEffect(() => {
    if (value) {
      const selected = options.find((item) => item.value === value)?.name;
      setSearchText(selected ?? '');
    }
  }, [name, onChange, onSearch, options, value]);

  useEffect(() => {
    if (!value) {
      setSearchText('');
      onSearch(undefined);
    }
  }, [onSearch, options, value]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.currentTarget.value;
    setSearchText(newValue);
    return onSearch(newValue);
  };

  return (
    <InputField {...props} isDisabled={isDisabled || isLoading}>
      <div
        className={clsx(
          'flex flex-col rounded-lg border border-info-300 shadow-sm focus:ring-blue-500',
          isDisabled && sharedClasses.disabled,
        )}
      >
        <div className="relative flex justify-center gap-2 border-b border-info-300">
          <IconMdi path={mdiMagnify} size={0.75} className="absolute left-2 top-1/2 -translate-y-1/2 text-info-400" />
          <Input
            autoFocus
            className="border-none pl-8 focus:outline-none"
            disabled={isLoading}
            key={value}
            name={name}
            onChange={changeHandler}
            placeholder={placeholder}
            value={searchText}
          />
          <Show when={!isEmptyValue(value)}>
            <TooltipedIcon
              placement="top"
              path={mdiCloseCircleOutline}
              className="mr-2 cursor-pointer text-info-400 transition-colors hover:text-error-500"
              size={0.625}
              content="Remove selection"
              onClick={() => onChange(undefined, name)}
            />
          </Show>
          <Show when={isLoading}>
            <Spinner className="absolute right-3 top-3 [&>path]:fill-info-400" size="xs" />
          </Show>
        </div>
        <ul className="relative flex h-44 flex-col gap-2 overflow-y-auto py-1 text-gray-700">
          <ShowFirstMatching>
            <Show when={isLoading}>
              <>
                {new Array(4).fill(null).map((_, idx) => (
                  <li className="flex animate-pulse px-3 py-2" key={`searchable-input-select-skeleton-${idx}`}>
                    <p className="h-4 w-3/4 rounded-lg bg-slate-200" />
                  </li>
                ))}
              </>
            </Show>
            <Show when={!isLoading}>
              <VirtualizedList<Option>
                data={searchResult}
                itemHeight={36}
                height={INNER_CONTAINER_HEIGHT_PX}
                renderItem={({ label, value: fieldValue }) => (
                  <li
                    className={clsx(
                      'cursor-pointer truncate px-3 py-2 text-sm font-normal leading-5 transition-colors hover:bg-info-100',
                      fieldValue === value && 'bg-info-100',
                    )}
                    key={fieldValue}
                    onClick={() => onChange(fieldValue, name)}
                  >
                    {label}
                  </li>
                )}
              />
              <Show when={searchResult.length === 0}>
                <span className="absolute inset-0 p-3 text-sm font-normal leading-5 text-gray-400 ">
                  {noOptionsLeftLabel}
                </span>
              </Show>
            </Show>
          </ShowFirstMatching>
        </ul>
      </div>
    </InputField>
  );
};
