import DOMPurify from 'dompurify';

const customAttributes = [
  'backgroundColor',
  'bold',
  'borderlinestyle',
  'colwidth',
  'contenteditable',
  'data-background-color',
  'data-cypress',
  'data-editing',
  'data-hidden',
  'data-id',
  'data-keep-ratio',
  'data-logo-path',
  'data-marker-size',
  'data-order',
  'data-parentid',
  'data-source',
  'data-test-block-id',
  'data-testid',
  'data-theme',
  'data-type',
  'data-vertical-align',
  'hidebottom',
  'hideleft',
  'hideright',
  'hidetop',
  'indent',
  'locked',
  'monofont',
  'pre-set-type',
  'rightalign',
];

export const sanitizeHtml = (html: string) => {
  const isPDFRender = typeof document === 'undefined';
  if (isPDFRender) {
    const jsdom = require('jsdom'); // do not change it to 'import from' because it will increase vendor bundle size by 6MB
    return DOMPurify(new jsdom.JSDOM('<!DOCTYPE html>').window).sanitize(html, { ADD_ATTR: customAttributes });
  }
  return DOMPurify.sanitize(html, { ADD_ATTR: customAttributes });
};
