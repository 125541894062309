import type { ResourceBlock } from '@root/@types/base';

interface BlockRulesHasBeenMovedByRenewalArgs {
  block: ResourceBlock;
  entityId?: string;
  movedBlockIds?: string[];
  renewalId?: string;
}

export const blockRulesHasBeenMovedByRenewal = ({
  block,
  entityId,
  movedBlockIds,
  renewalId,
}: BlockRulesHasBeenMovedByRenewalArgs) => {
  if (!entityId || !renewalId || !movedBlockIds) {
    return false;
  }

  return renewalId === entityId && movedBlockIds.includes(block.id);
};
