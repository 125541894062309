export const isTemplatePath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/templates');
};

export const isBlocksPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/blocks');
};
export const isBrandingPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/branding');
};

export const isSubmissionPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/contracts');
};

export const isEndorsementPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.includes('/endorsements/');
};

export const isEndorsementViewPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.includes('/endorsement-view');
};

export const isPPLFrontend =
  (typeof import.meta !== 'undefined' && import.meta.env?.VITE_CLIENT === 'ct-ppl') ||
  (typeof process !== 'undefined' && process?.env?.CLIENT === 'ct-ppl');
