import React from 'react';
import type { StatusType } from '@ContractBuilder/types';
import { StatusBadge } from '@pages/User/components';
import { SUBMISSION_STATUSES } from '@root/@types/types';
import { getFormattedStatus } from '@root/src/utils/get-formatted-status';
import type { CellContext, ColumnHelper } from '@tanstack/react-table';

export const statusAccessor = <T extends { status?: string }>(columnHelper: ColumnHelper<T>) =>
  columnHelper.accessor((entity) => entity.status, {
    id: 'status',
    header: 'Status',
    filterFn: 'arrIncludesSome',
    cell: (cellContext: CellContext<T, StatusType>) => <StatusBadge status={cellContext.getValue()} />,
    meta: {
      filter: {
        placeholder: 'All statuses',
        selected: SUBMISSION_STATUSES.filter((status) => status !== 'ARCHIVED'),
        options: SUBMISSION_STATUSES.map((status) => ({
          name: getFormattedStatus(status),
          value: status,
        })),
      },
      dataCellClassName: '!w-24 align-top !p-[1px]',
      headingCellClassName: '!w-24',
      headingLinkClassName: '!pl-0',
    },
  });
