import React from 'react';

interface SvgProps {
  name: SvgIconName;
}

const Svg: React.FC<SvgProps> = ({ name }) => {
  switch (name) {
    case 'alert-circle':
      return (
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.6367" cy="14.4297" r="13" className="fill-amber-400" stroke="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6367 14.4297C21.6367 18.848 18.055 22.4297 13.6367 22.4297C9.21844 22.4297 5.63672 18.848 5.63672 14.4297C5.63672 10.0114 9.21844 6.42969 13.6367 6.42969C18.055 6.42969 21.6367 10.0114 21.6367 14.4297ZM14.6367 10.4297C14.6367 10.982 14.189 11.4297 13.6367 11.4297C13.0844 11.4297 12.6367 10.982 12.6367 10.4297C12.6367 9.8774 13.0844 9.42969 13.6367 9.42969C14.189 9.42969 14.6367 9.8774 14.6367 10.4297ZM12.6367 13.4297C12.0844 13.4297 11.6367 13.8774 11.6367 14.4297C11.6367 14.982 12.0844 15.4297 12.6367 15.4297V18.4297C12.6367 18.982 13.0844 19.4297 13.6367 19.4297H14.6367C15.189 19.4297 15.6367 18.982 15.6367 18.4297C15.6367 17.8774 15.189 17.4297 14.6367 17.4297V14.4297C14.6367 13.8774 14.189 13.4297 13.6367 13.4297H12.6367Z"
            className="fill-amber-50"
          />
        </svg>
      );
    case 'move':
      return (
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.6367" cy="14.4297" r="13" className="fill-[#FBBF24]" stroke="white" />
          <path
            d="M3.63672 9.5C3.63672 10.0523 4.08444 10.5 4.63673 10.5C5.18901 10.5 5.63673 10.0523 5.63673 9.5L5.63673 3.91421L6.92963 5.20711C7.32015 5.59763 7.95332 5.59763 8.34384 5.20711C8.73437 4.81658 8.73437 4.18342 8.34384 3.79289L5.34384 0.792893C5.1563 0.605357 4.90195 0.5 4.63673 0.5C4.37151 0.5 4.11716 0.605357 3.92962 0.792893L0.929613 3.79289C0.539087 4.18342 0.539087 4.81658 0.929613 5.20711C1.32014 5.59763 1.9533 5.59763 2.34383 5.20711L3.63673 3.91421L3.63672 9.5Z"
            fill="#F9FAFB"
            className="translate-x-[5px] translate-y-2 fill-info-50"
          />
          <path
            d="M13.6368 5.5C13.6368 4.94772 13.189 4.5 12.6367 4.5C12.0845 4.5 11.6367 4.94772 11.6367 5.5L11.6367 11.0858L10.3438 9.79289C9.95332 9.40237 9.32016 9.40237 8.92963 9.79289C8.53911 10.1834 8.53911 10.8166 8.92963 11.2071L11.9296 14.2071C12.1172 14.3946 12.3715 14.5 12.6367 14.5C12.902 14.5 13.1563 14.3946 13.3439 14.2071L16.3439 11.2071C16.7344 10.8166 16.7344 10.1834 16.3439 9.79289C15.9533 9.40237 15.3202 9.40237 14.9296 9.79289L13.6367 11.0858L13.6368 5.5Z"
            fill="#F9FAFB"
            className="translate-x-[5px] translate-y-1.5 fill-info-50"
          />
        </svg>
      );
    case 'create':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M20.719 7.031l-1.828 1.828-3.75-3.75 1.828-1.828q0.281-0.281 0.703-0.281t0.703 0.281l2.344 2.344q0.281 0.281 0.281 0.703t-0.281 0.703zM3 17.25l11.063-11.063 3.75 3.75-11.063 11.063h-3.75v-3.75z" />
        </svg>
      );

    case 'double-arrow':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M8.484 5.016l5.016 6.984-5.016 6.984h-4.5l5.016-6.984-5.016-6.984h4.5zM15.516 5.016l4.969 6.984-4.969 6.984h-4.5l4.969-6.984-4.969-6.984h4.5z" />
        </svg>
      );

    case 'insert-drive-file':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z" />
        </svg>
      );

    case 'file-copy':
      return (
        <svg id="icon-file_copy" viewBox="0 0 24 24">
          <path d="M14.016 12h5.484l-5.484-5.484v5.484zM15 5.016l6 6v9.984q0 0.797-0.609 1.406t-1.406 0.609h-11.016q-0.797 0-1.383-0.609t-0.586-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h6.984zM15.984 0.984v2.016h-12v14.016h-1.969v-14.016q0-0.797 0.586-1.406t1.383-0.609h12z" />
        </svg>
      );

    case 'replay':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12 5.016q3.328 0 5.672 2.344t2.344 5.625q0 3.328-2.367 5.672t-5.648 2.344-5.648-2.344-2.367-5.672h2.016q0 2.484 1.758 4.242t4.242 1.758 4.242-1.758 1.758-4.242-1.758-4.242-4.242-1.758v4.031l-5.016-5.016 5.016-5.016v4.031z" />
        </svg>
      );

    case 'clock':
      return (
        <svg id="icon-access_time" viewBox="0 0 24 24">
          <path d="M12.516 6.984v5.25l4.5 2.672-0.75 1.266-5.25-3.188v-6h1.5zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z" />
        </svg>
      );

    case 'maximize':
      return (
        <svg id="icon-crop_square" viewBox="0 0 24 24">
          <polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" />
        </svg>
      );

    case 'minimize':
      return (
        <svg id="icon-minimize" viewBox="0 0 24 24">
          <path d="M6 18.984h12v2.016h-12v-2.016z" />
        </svg>
      );

    case 'unfold-less':
      return (
        <svg id="icon-unfold_less" viewBox="0 0 24 24">
          <path d="M16.594 5.391l-4.594 4.594-4.594-4.594 1.406-1.406 3.188 3.188 3.188-3.188zM7.406 18.609l4.594-4.594 4.594 4.594-1.406 1.406-3.188-3.188-3.188 3.188z" />
        </svg>
      );

    case 'unfold-more':
      return (
        <svg id="icon-unfold_more" viewBox="0 0 24 24">
          <path d="M12 18.188l3.188-3.188 1.406 1.406-4.594 4.594-4.594-4.594 1.406-1.406zM12 5.813l-3.188 3.188-1.406-1.406 4.594-4.594 4.594 4.594-1.406 1.406z" />
        </svg>
      );

    case 'messages':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18 8.016v-2.016h-12v2.016h12zM18 11.016v-2.016h-12v2.016h12zM18 14.016v-2.016h-12v2.016h12zM20.016 2.016q0.797 0 1.383 0.586t0.586 1.383v12q0 0.797-0.586 1.406t-1.383 0.609h-14.016l-3.984 3.984v-18q0-0.797 0.586-1.383t1.383-0.586h16.031z" />
        </svg>
      );

    case 'find-in-page':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M9 12.984q0-1.219 0.891-2.109t2.109-0.891 2.109 0.891 0.891 2.109-0.891 2.109-2.109 0.891-2.109-0.891-0.891-2.109zM20.016 19.594l-3.844-3.844q0.844-1.313 0.844-2.766 0-2.063-1.477-3.516t-3.539-1.453-3.539 1.453-1.477 3.516 1.477 3.539 3.539 1.477q1.453 0 2.766-0.844l4.406 4.453q-0.516 0.375-1.172 0.375h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586h8.016l6 6v11.578z" />
        </svg>
      );

    case 'upload':
      return (
        <svg viewBox="0 0 14 14">
          <path d="M0 1.63336L14 1.63336L14 2.88486e-05L1.42791e-07 2.76247e-05L0 1.63336Z" />
          <path d="M8.54175 14L4.79175 14L4.79175 8.44444L1.66675 8.44444L6.66675 4L11.6667 8.44444L8.54175 8.44444L8.54175 14Z" />
        </svg>
      );

    case 'calendar':
      return (
        <svg viewBox="0 0 16 16">
          <path d="M16 3.886V2.9a.5.5 0 00-.5-.5h-2.014V0H10.97v2.514H5.03V0H2.514v2.514H.457A.457.457 0 000 2.971V15.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V3.886zM1.6 5.943h12.686v8.343H1.6V5.943z" />
        </svg>
      );

    case 'checkmark':
      return (
        <svg viewBox="0 0 12 10">
          <path d="M3.916 7.645L1.204 4.8l-.918.963 3.63 3.808 7.798-8.18-.918-.962-6.88 7.216z" />
        </svg>
      );

    case 'warning':
      return (
        <svg viewBox="0 0 48 48">
          <path d="M24 4.8C13.396 4.8 4.8 13.396 4.8 24S13.396 43.2 24 43.2 43.2 34.604 43.2 24 34.604 4.8 24 4.8zM0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z" />
          <path d="M21.6 28.457V11.314h4.8v17.143h-4.8zM21.6 36.686v-4.8h4.8v4.8h-4.8z" />
        </svg>
      );

    case 'paperclip':
      return (
        <svg viewBox="0 0 14 14">
          <path d="M9.045 3.182V10.5a2.545 2.545 0 11-5.09 0V2.545a1.591 1.591 0 013.181 0v6.682c0 .35-.286.637-.636.637a.638.638 0 01-.636-.637V3.182h-.955v6.045a1.591 1.591 0 003.182 0V2.545a2.545 2.545 0 10-5.091 0V10.5C3 12.434 4.565 14 6.5 14s3.5-1.566 3.5-3.5V3.182h-.955z" />
        </svg>
      );

    case 'lock':
      return (
        <svg viewBox="0 0 12 16">
          <path
            fillRule="evenodd"
            d="M4.514 3.086C4.514 2.27 5.184 1.6 6 1.6c.815 0 1.486.67 1.486 1.486v2.628H4.514V3.086zm-1.6 2.628V3.086A3.092 3.092 0 016 0a3.092 3.092 0 013.086 3.086v2.628h2.628V16H.286V5.714h2.628zM6 12.571a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
            clipRule="evenodd"
          />
        </svg>
      );

    case 'lock2':
      return (
        <svg viewBox="0 0 12 14" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 6.2001V4.6001C2 2.39096 3.79086 0.600098 5.99999 0.600098C8.20913 0.600098 9.99999 2.39096 9.99999 4.6001V6.2001C10.8836 6.2001 11.6 6.91644 11.6 7.8001V11.8001C11.6 12.6838 10.8836 13.4001 9.99999 13.4001H2C1.11634 13.4001 0.399998 12.6838 0.399998 11.8001V7.8001C0.399998 6.91644 1.11634 6.2001 2 6.2001ZM8.39999 4.6001V6.2001H3.6V4.6001C3.6 3.27461 4.67451 2.2001 5.99999 2.2001C7.32548 2.2001 8.39999 3.27461 8.39999 4.6001Z"
            fill="#6B7280"
          />
        </svg>
      );

    case 'edit':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24">
          <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
        </svg>
      );

    case 'search':
      return (
        <svg viewBox="0 0 16 16">
          <path d="M5.486 1.6a3.886 3.886 0 100 7.771 3.886 3.886 0 000-7.771zM0 5.486a5.486 5.486 0 1110.971 0A5.486 5.486 0 010 5.486z" />
          <path d="M10.83 9.34a.5.5 0 01.244.134l4.572 4.572a.5.5 0 010 .708l-.892.892a.5.5 0 01-.707 0l-4.573-4.572a.5.5 0 01-.134-.245l-.426-1.915 1.915.426z" />
        </svg>
      );

    case 'cross':
      return (
        <svg viewBox="0 0 14 14">
          <path d="M6.012 7L1 12.012l.988.988L7 7.988 12.012 13l.988-.988L7.988 7 13 1.988 12.012 1 7 6.012 1.988 1 1 1.988 6.012 7z" />
        </svg>
      );

    case 'info':
      return (
        <svg viewBox="0 0 13 14">
          <path d="M6.5 12.2a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4zM13 7A6.5 6.5 0 110 7a6.5 6.5 0 0113 0z" />
          <path d="M7.15 5.793v4.643h-1.3V5.793h1.3zM7.15 3.564v1.3h-1.3v-1.3h1.3z" />
        </svg>
      );

    case 'chevron-up':
      return (
        <svg width="12" height="7">
          <path fillRule="evenodd" d="M0 6l1 1 5-5 5 5 1-1-6-6-1 1-5 5z" clipRule="evenodd" />
        </svg>
      );

    case 'chevron-down':
      return (
        <svg width="12" height="7">
          <path fillRule="evenodd" d="M12 1l-1-1-5 5-5-5-1 1 6 6 1-1 5-5z" clipRule="evenodd" />
        </svg>
      );
    case 'chevron-right':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-4 w-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      );

    case 'circle-edit':
      return (
        <svg viewBox="0 0 32 32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#339AF0" />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M17.951 11.596l2.1-2.1a.5.5 0 01.707 0l1.746 1.746a.5.5 0 010 .707l-2.1 2.1-2.453-2.453zm-1.003 1.003l2.453 2.453-7.659 7.659a.5.5 0 01-.353.146H9.143v-2.246a.5.5 0 01.146-.353l7.659-7.659z"
            clipRule="evenodd"
          />
        </svg>
      );

    case 'circle-check':
      return (
        <svg viewBox="0 0 32 32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#51CF66" />
          <path fill="#fff" d="M13.916 18.645L11.204 15.8l-.918.963 3.63 3.808 7.798-8.179-.918-.963-6.88 7.216z" />
        </svg>
      );

    case 'circle-lock':
      return (
        <svg width="32" height="32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#E9ECEF" />
          <path
            fill="#495057"
            fillRule="evenodd"
            d="M14.514 11.086c0-.816.67-1.486 1.486-1.486.815 0 1.486.67 1.486 1.486v2.628h-2.972v-2.628zm-1.6 2.628v-2.628A3.092 3.092 0 0116 8a3.092 3.092 0 013.086 3.086v2.628h2.628V24H10.286V13.714h2.628zM16 20.571a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
            clipRule="evenodd"
          />
        </svg>
      );

    case 'logout':
      return (
        <svg width="15" height="14">
          <g fill="#495057" fillRule="evenodd">
            <path d="M10.42 0h-9a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h9.9a.5.5 0 00.5-.5v-3h-1.4v2.1h-8.1V1.4h8.1v2.1h1.4v-3a.5.5 0 00-.5-.5h-.9z" />
            <path d="M11.82 4.4l3.1 2.6-3.1 2.6V7.7h-7.8V6.3h7.8V4.4z" />
          </g>
        </svg>
      );

    case 'details':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26">
          <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
        </svg>
      );

    case 'trashcan':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
        </svg>
      );

    case 'star-empty':
      return (
        <svg id="icon-star-empty" viewBox="0 0 32 32">
          <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798zM16 23.547l-6.983 3.671 1.334-7.776-5.65-5.507 7.808-1.134 3.492-7.075 3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776-6.983-3.671z"></path>
        </svg>
      );

    case 'star-full':
      return (
        <svg id="icon-star-full" viewBox="0 0 32 32">
          <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798z"></path>
        </svg>
      );
    case 'dots-vertical':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          />
        </svg>
      );
    case 'hand-empty':
      return (
        <svg
          id="icon-hand-empty"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
          />
        </svg>
      );
    case 'settings':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      );
    case 'plus':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
        </svg>
      );
    case 'arrow-up':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      );
    case 'arrow-down':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
        </svg>
      );
    case 'comment':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
          />
        </svg>
      );
    case 'layout':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="4" fill="currentColor" />
          <path
            d="M3.09961 3.79961C3.09961 3.41301 3.41301 3.09961 3.79961 3.09961H12.1996C12.5862 3.09961 12.8996 3.41301 12.8996 3.79961V5.19961C12.8996 5.58621 12.5862 5.89961 12.1996 5.89961H3.79961C3.41301 5.89961 3.09961 5.58621 3.09961 5.19961V3.79961Z"
            fill="white"
          />
          <path
            d="M3.09961 7.99961C3.09961 7.61301 3.41301 7.29961 3.79961 7.29961H7.99961C8.38621 7.29961 8.69961 7.61301 8.69961 7.99961V12.1996C8.69961 12.5862 8.38621 12.8996 7.99961 12.8996H3.79961C3.41301 12.8996 3.09961 12.5862 3.09961 12.1996V7.99961Z"
            fill="white"
          />
          <path
            d="M10.7996 7.29961C10.413 7.29961 10.0996 7.61301 10.0996 7.99961V12.1996C10.0996 12.5862 10.413 12.8996 10.7996 12.8996H12.1996C12.5862 12.8996 12.8996 12.5862 12.8996 12.1996V7.99961C12.8996 7.61301 12.5862 7.29961 12.1996 7.29961H10.7996Z"
            fill="white"
          />
        </svg>
      );
    case 'align-left':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={'h-6 w-6'}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
        </svg>
      );
    case 'preview-pdf':
      return (
        <svg
          className="inline"
          width="13"
          height="17"
          viewBox="0 0 13 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 2C1 0.895432 1.89543 0 3 0H7.58579C8.11622 0 8.62493 0.210714 9 0.585786L12.4142 4C12.7893 4.37507 13 4.88378 13 5.41421V14C13 15.1046 12.1046 16 11 16H9.47221C10.4223 14.9385 11 13.5367 11 12C11 8.68629 8.31371 6 5 6C3.46329 6 2.06151 6.57771 1 7.52779V2Z"
            fill="#9CA3AF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 8C2.79086 8 1 9.79086 1 12C1 12.7414 1.20229 13.4364 1.55397 14.0318L0.292893 15.2929C-0.097631 15.6834 -0.0976311 16.3166 0.292893 16.7071C0.683417 17.0976 1.31658 17.0976 1.70711 16.7071L2.96818 15.446C3.56362 15.7977 4.25862 16 5 16C7.20914 16 9 14.2091 9 12C9 9.79086 7.20914 8 5 8ZM3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C4.44744 14 3.94881 13.7772 3.58579 13.4142C3.22276 13.0512 3 12.5526 3 12Z"
            fill="#9CA3AF"
          />
        </svg>
      );
    case 'download-pdf':
      return (
        <svg
          className="mr-2 inline"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.33398 11.3333L1.33398 12.1666C1.33398 13.5473 2.45327 14.6666 3.83398 14.6666L12.1673 14.6666C13.548 14.6666 14.6673 13.5473 14.6673 12.1666L14.6673 11.3333M11.334 7.99996L8.00065 11.3333M8.00065 11.3333L4.66732 7.99996M8.00065 11.3333L8.00065 1.33329"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'list-bullet':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
          />
        </svg>
      );

    case 'paper-airplane':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
          />
        </svg>
      );

    case 'speech-bubble':
      return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.83333 4.66732H13.1667M4.83333 8.00065H8.16667M9 14.6673L5.66667 11.334H3.16667C2.24619 11.334 1.5 10.5878 1.5 9.66732V3.00065C1.5 2.08018 2.24619 1.33398 3.16667 1.33398H14.8333C15.7538 1.33398 16.5 2.08018 16.5 3.00065V9.66732C16.5 10.5878 15.7538 11.334 14.8333 11.334H12.3333L9 14.6673Z"
            stroke="#4B5563"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'book':
      return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none">
          <path
            d="M8 1.68959V11.4396M8 1.68959C7.12408 1.10765 5.93487 0.75 4.625 0.75C3.31513 0.75 2.12592 1.10765 1.25 1.68959V11.4396C2.12592 10.8576 3.31513 10.5 4.625 10.5C5.93487 10.5 7.12408 10.8576 8 11.4396M8 1.68959C8.87592 1.10765 10.0651 0.75 11.375 0.75C12.6849 0.75 13.8741 1.10765 14.75 1.68959V11.4396C13.8741 10.8576 12.6849 10.5 11.375 10.5C10.0651 10.5 8.87592 10.8576 8 11.4396"
            stroke="#4B5563"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'duplicate':
      return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M3.60039 5.20039C3.60039 4.31674 4.31674 3.60039 5.20039 3.60039H10.0004C10.884 3.60039 11.6004 4.31674 11.6004 5.20039V10.0004C11.6004 10.884 10.884 11.6004 10.0004 11.6004H5.20039C4.31674 11.6004 3.60039 10.884 3.60039 10.0004V5.20039Z"
            fill="currentColor"
          />
          <path
            d="M2.00039 0.400391C1.11674 0.400391 0.400391 1.11673 0.400391 2.00039V6.80039C0.400391 7.68405 1.11674 8.40039 2.00039 8.40039L2.00039 2.00039H8.40039C8.40039 1.11674 7.68405 0.400391 6.80039 0.400391H2.00039Z"
            fill="currentColor"
          />
        </svg>
      );

    case 'lightning-bolt':
      return (
        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.30064 0.427194C8.71692 0.558414 9 0.944462 9 1.38093V6.38093L13 6.38093C13.3729 6.38093 13.7148 6.58839 13.887 6.91912C14.0592 7.24985 14.0331 7.64892 13.8192 7.95439L6.81924 17.9544C6.56894 18.312 6.11564 18.4659 5.69936 18.3347C5.28309 18.2034 5 17.8174 5 17.3809L5 12.3809H1C0.627127 12.3809 0.285222 12.1735 0.113024 11.8427C-0.0591735 11.512 -0.0330591 11.1129 0.180771 10.8075L7.18077 0.80747C7.43107 0.449899 7.88436 0.295974 8.30064 0.427194Z"
            fill="#4B5563"
          />
        </svg>
      );
    case 'submissions':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 10V3C17 1.89543 16.1046 1 15 1H3C1.89543 1 1 1.89543 1 3V10M17 10V15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15V10M17 10H14.4142C14.149 10 13.8946 10.1054 13.7071 10.2929L11.2929 12.7071C11.1054 12.8946 10.851 13 10.5858 13H7.41421C7.149 13 6.89464 12.8946 6.70711 12.7071L4.29289 10.2929C4.10536 10.1054 3.851 10 3.58579 10H1"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'templates':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 2C1 1.44772 1.44772 1 2 1H16C16.5523 1 17 1.44772 17 2V4C17 4.55228 16.5523 5 16 5H2C1.44772 5 1 4.55228 1 4V2Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 10C1 9.44772 1.44772 9 2 9H8C8.55228 9 9 9.44772 9 10V16C9 16.5523 8.55228 17 8 17H2C1.44772 17 1 16.5523 1 16V10Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 10C13 9.44772 13.4477 9 14 9H16C16.5523 9 17 9.44772 17 10V16C17 16.5523 16.5523 17 16 17H14C13.4477 17 13 16.5523 13 16V10Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'blocks':
      return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 15C1.89543 15 1 14.1046 1 13V3C1 1.89543 1.89543 1 3 1H7L9 3H13C14.1046 3 15 3.89543 15 5V6M3 15H17C18.1046 15 19 14.1046 19 13V8C19 6.89543 18.1046 6 17 6H7C5.89543 6 5 6.89543 5 8V13C5 14.1046 4.10457 15 3 15Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
    case 'tags':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 5H5.01M5 1H10C10.5119 0.999985 11.0237 1.19525 11.4142 1.58579L18.4143 8.58579C19.1953 9.36684 19.1953 10.6332 18.4143 11.4142L11.4142 18.4142C10.6332 19.1953 9.36683 19.1953 8.58579 18.4142L1.58579 11.4142C1.19526 11.0237 1 10.5118 1 10V5C1 2.79086 2.79086 1 5 1Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'users':
      return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 17H21V15C21 13.3431 19.6569 12 18 12C17.0444 12 16.1931 12.4468 15.6438 13.1429M16 17H6M16 17V15C16 14.3438 15.8736 13.717 15.6438 13.1429M6 17H1V15C1 13.3431 2.34315 12 4 12C4.95561 12 5.80686 12.4468 6.35625 13.1429M6 17V15C6 14.3438 6.12642 13.717 6.35625 13.1429M6.35625 13.1429C7.0935 11.301 8.89482 10 11 10C13.1052 10 14.9065 11.301 15.6438 13.1429M14 4C14 5.65685 12.6569 7 11 7C9.34315 7 8 5.65685 8 4C8 2.34315 9.34315 1 11 1C12.6569 1 14 2.34315 14 4ZM20 7C20 8.10457 19.1046 9 18 9C16.8954 9 16 8.10457 16 7C16 5.89543 16.8954 5 18 5C19.1046 5 20 5.89543 20 7ZM6 7C6 8.10457 5.10457 9 4 9C2.89543 9 2 8.10457 2 7C2 5.89543 2.89543 5 4 5C5.10457 5 6 5.89543 6 7Z"
            stroke="#9CA3AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'pencil-edit':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path
            d="M7.16732 3.16667H3.00065C2.08018 3.16667 1.33398 3.91286 1.33398 4.83334V14C1.33398 14.9205 2.08018 15.6667 3.00065 15.6667H12.1673C13.0878 15.6667 13.834 14.9205 13.834 14V9.83334M12.6555 1.98816C13.3063 1.33728 14.3616 1.33728 15.0125 1.98816C15.6634 2.63903 15.6634 3.6943 15.0125 4.34518L7.85767 11.5H5.50065L5.50065 9.14298L12.6555 1.98816Z"
            stroke="#4B5563"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'photo':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
      );
    case 'support':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM15.25 12C15.25 13.7949 13.7949 15.25 12 15.25V16.75C14.6234 16.75 16.75 14.6234 16.75 12H15.25ZM12 15.25C10.2051 15.25 8.75 13.7949 8.75 12H7.25C7.25 14.6234 9.37665 16.75 12 16.75V15.25ZM8.75 12C8.75 10.2051 10.2051 8.75 12 8.75V7.25C9.37665 7.25 7.25 9.37665 7.25 12H8.75ZM12 8.75C13.7949 8.75 15.25 10.2051 15.25 12H16.75C16.75 9.37665 14.6234 7.25 12 7.25V8.75ZM17.8336 5.10571L14.2981 8.64124L15.3588 9.7019L18.8943 6.16637L17.8336 5.10571ZM14.2981 15.3588L17.8336 18.8943L18.8943 17.8336L15.3588 14.2981L14.2981 15.3588ZM9.7019 8.64124L6.16637 5.10571L5.10571 6.16637L8.64124 9.7019L9.7019 8.64124ZM8.64124 14.2981L5.10571 17.8336L6.16637 18.8943L9.7019 15.3588L8.64124 14.2981Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default Svg;

export type SvgIconName =
  | 'alert-circle'
  | 'align-left'
  | 'arrow-down'
  | 'arrow-up'
  | 'blocks'
  | 'book'
  | 'calendar'
  | 'checkmark'
  | 'chevron-down'
  | 'chevron-up'
  | 'chevron-right'
  | 'circle-check'
  | 'circle-edit'
  | 'circle-lock'
  | 'clock'
  | 'comment'
  | 'create'
  | 'cross'
  | 'details'
  | 'dots-vertical'
  | 'double-arrow'
  | 'download-pdf'
  | 'duplicate'
  | 'edit'
  | 'file-copy'
  | 'find-in-page'
  | 'hand-empty'
  | 'info'
  | 'insert-drive-file'
  | 'layout'
  | 'lightning-bolt'
  | 'list-bullet'
  | 'lock'
  | 'lock2'
  | 'logout'
  | 'maximize'
  | 'messages'
  | 'minimize'
  | 'move'
  | 'paperclip'
  | 'pencil-edit'
  | 'photo'
  | 'plus'
  | 'preview-pdf'
  | 'replay'
  | 'search'
  | 'settings'
  | 'star-empty'
  | 'star-full'
  | 'submissions'
  | 'tags'
  | 'templates'
  | 'trashcan'
  | 'unfold-less'
  | 'unfold-more'
  | 'upload'
  | 'users'
  | 'warning'
  | 'paper-airplane'
  | 'speech-bubble'
  | 'support';
