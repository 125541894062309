import { useMemo } from 'react';
import type { QueryStatus } from 'react-query';

export const useDataIsEmpty = (data: any[] | undefined, status: QueryStatus): boolean => {
  return useMemo(() => {
    if (status === 'success') {
      return !data?.length;
    }
    // if data is still being fetched to avoid unnecessary rendering empty states
    return false;
  }, [status, data?.length]);
};
