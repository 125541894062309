import type { FC } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';

import type { CreateRenewalFormState } from '../types';

interface WarningBannerProps {
  formValues: CreateRenewalFormState;
  type?: 'skipPreviouslyRemovedBlocks' | 'keepPreviouslyAddedBlocks';
}

const getBannerContent = (type: WarningBannerProps['type']) => {
  if (type === 'skipPreviouslyRemovedBlocks') {
    return 'Skipping previously removed blocks will not reinstate any blocks that were removed from the original template, on the new template.';
  }

  if (type === 'keepPreviouslyAddedBlocks') {
    return "Keeping additional blocks retains any contract blocks not in the new template. These will be positioned below the new template's blocks.";
  }

  return 'Changing the template for this renewal contract will reset any edited content, including additional text and formatting changes.';
};

export const WarningBanner: FC<WarningBannerProps> = ({ formValues, type }) => {
  const content = getBannerContent(type);
  const shouldShowBanner = type ? formValues[type] : true;

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <div className="flex items-start gap-x-3 rounded-md bg-amber-50 p-4">
      <div>
        <ExclamationIcon className="h-5 w-5 text-amber-600" aria-hidden="true" />
      </div>
      <p className="text-sm font-medium leading-5 text-amber-800">{content}</p>
    </div>
  );
};
