import type { Control, UseFormSetValue } from 'react-hook-form';
import type { z } from 'zod';

import type { step4Schema } from './validation';

export enum Step {
  GeneralEntityData = 1,
  BaseEntityData = 2,
  BrandingInfo = 3,
  MrcFileUpload = 4,
}

export enum DataType {
  Blank = 'blank',
  Pdf = 'pdf',
}

export type CreateEntityFormState = z.infer<typeof step4Schema>;

export interface StepProps {
  control: Control<CreateEntityFormState>;
  formValues: CreateEntityFormState;
  setValue: UseFormSetValue<CreateEntityFormState>;
}

export type EntityModalType = 'template' | 'contract';
