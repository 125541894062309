import React, { memo, useEffect } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useDocumentPreviewNavigationIcon } from '@MrcExtraction/modules/mrc-document-view';
import clsx from 'clsx';
import { cloneDeep, get, isEqual, set, sortBy } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';

import type { JSONSchema, JSONSchemaProperties, UIChildrenMappingProps } from '../@types/types';

import { getFieldError } from './formatErrors';
import { getComponentType } from './getComponentType';

const getUIProps = (item: JSONSchemaProperties) => {
  const OMIT = [
    'ui:component',
    'ui:component:readonly',
    'ui:className',
    'ui:description',
    'ui:hidden',
    'ui:limitValueLengthPx',
  ];

  const uiKeys = Object.keys(item).filter((key) => key.startsWith('ui:'));
  const uiProps = uiKeys.reduce((acc: Record<string, any>, key: string) => {
    if (!OMIT.includes(key)) {
      const [, propKey] = key.split(':');
      acc[propKey] = get(item, key);
    }
    return acc;
  }, {});

  return uiProps;
};

export interface MapChildrenArgs extends Omit<UIChildrenMappingProps, 'formValues' | 'setFormValues'> {
  isReadOnly?: boolean;
  hideErrors?: boolean;
  onBlur?: () => void;
  parentKey: string;
  parentSchema: JSONSchema;
  useParentKey?: boolean;
  isDisabled?: boolean;
  toggleDependsOn?: string;
}

type MapChildrenFn = React.FC<MapChildrenArgs>;

interface MakeComponentProps extends MapChildrenArgs {
  item: JSONSchemaProperties & { key: string };
}

const MakeComponentInner: React.FC<MakeComponentProps> = ({
  autoFocus,
  ctx,
  isDisabled,
  isReadOnly,
  item,
  hideErrors,
  onBlur,
  onChange,
  parentKey,
  parentSchema,
  showQuestionId,
  showQuestionKey,
  useParentKey,
  validationErrors,
}) => {
  const Component = getComponentType(item);

  const getDocumentNavigationIconComponent = useDocumentPreviewNavigationIcon();

  const replace = useDatapointsStore(({ formValues }) =>
    Array.isArray(item.replace) && item.replace.length === 2
      ? [item.replace[0], formValues[item.replace[1]] as string]
      : null,
  );
  const setFormValues = useDatapointsStore(({ setFormValues }) => setFormValues);

  const isHidden = get(item, 'ui:hidden');
  const constantValue = get(item, 'const');

  let ownKey = parentKey === '' ? item.key : [parentKey, item.key].join('.');
  const schemaPropertiesKeys = Object.keys(parentSchema.properties);

  if (useParentKey) {
    ownKey = parentKey;
  }

  useEffect(() => {
    if (constantValue) {
      setFormValues((formValues) => {
        const clonedFormValues = cloneDeep(formValues);
        set(clonedFormValues, ownKey, constantValue);
        return clonedFormValues;
      });
    }
  }, [constantValue, ownKey, setFormValues]);

  useDeepCompareEffect(() => {
    const formValues = useDatapointsStore.getState().formValues;
    const fieldsWithDefaults = schemaPropertiesKeys.filter(
      (i) => parentSchema.properties[i].value !== undefined && !(i in formValues),
    );

    if (fieldsWithDefaults.length) {
      setFormValues((current) => {
        const nextFormValues = cloneDeep(current);
        fieldsWithDefaults.forEach((i) => set(nextFormValues, i, parentSchema.properties[i].value));
        return nextFormValues;
      });
    }
  }, [parentSchema.properties, schemaPropertiesKeys, setFormValues]);

  if (!Component) {
    return null;
  }
  const itemTitle = replace?.[1] ? item.title.replace(replace[0], replace[1]) : item.title;
  const isRequired = [...get(parentSchema, ['required'], [])].includes(item.key);
  const uiProps = getUIProps(item);
  const labelIcon = getDocumentNavigationIconComponent(ownKey);
  const errors = getFieldError(validationErrors, ownKey, item['ui:component']);

  return (
    <Component
      autoFocus={autoFocus}
      cdrId={item.cdrId}
      cdrName={item.cdrName}
      mrcId={item.mrcId}
      className={clsx('py-2', isHidden && 'hidden', get(item, 'ui:className'))}
      ctx={ctx}
      descriptionText={get(item, 'ui:description')}
      errors={errors}
      iconPosition="right"
      id={item.key}
      isClearable
      isDisabled={isDisabled}
      isHidden={isHidden}
      isReadOnly={isReadOnly || get(item, 'ui:component:readonly')}
      isRequired={isRequired}
      isSearchable={('enum' in item || 'enum' in get(item, 'items', {})) && item.isSearchable}
      item={item}
      helperText={item.helperText}
      key={item.key}
      labelIcon={labelIcon}
      labelText={
        (showQuestionId && `${item.qid}. ${itemTitle}`) || (showQuestionKey && <span>{itemTitle}</span>) || itemTitle
      }
      shortName={item?.shortName}
      MakeComponent={MakeComponent}
      name={ownKey}
      hideErrors={hideErrors}
      onBlur={onBlur}
      onChange={onChange}
      options={item?.enum ?? item?.items?.enum ?? []}
      ownKey={ownKey}
      parentKey={parentKey}
      parentSchema={parentSchema}
      placeholderText={item.placeholderText}
      showQuestionId={showQuestionId}
      showQuestionKey={showQuestionKey}
      validationErrors={validationErrors}
      {...uiProps}
    />
  );
};

export const MakeComponent = memo(MakeComponentInner, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});

const mapChildren: MapChildrenFn = ({
  ctx,
  isReadOnly = false,
  hideErrors,
  parentKey,
  parentSchema,
  useParentKey,
  validationErrors,
  showQuestionId,
  showQuestionKey,
  onBlur,
  onChange,
  isDisabled,
}) => {
  const mappedProperties = Object.keys(parentSchema.properties).map((key) => ({
    ...parentSchema.properties[key],
    key,
  }));
  const sortedProperties = sortBy(mappedProperties, (c) => c.qid);

  return (
    <>
      {sortedProperties.map((item) => (
        <MakeComponent
          ctx={ctx}
          isReadOnly={isReadOnly}
          item={item}
          isDisabled={isDisabled}
          key={item.key}
          hideErrors={hideErrors}
          onBlur={onBlur}
          onChange={onChange}
          parentKey={parentKey}
          parentSchema={parentSchema}
          showQuestionId={showQuestionId}
          showQuestionKey={showQuestionKey}
          useParentKey={useParentKey}
          validationErrors={validationErrors}
        />
      ))}
    </>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default mapChildren;
