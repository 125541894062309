import { cva } from 'class-variance-authority';

export const responsiveCellClasses = cva([], {
  variants: {
    breakpoint: {
      false: [],
      xs: ['hidden', 'xs:table-cell'],
      sm: ['hidden', 'sm:table-cell'],
      md: ['hidden', 'md:table-cell'],
      lg: ['hidden', 'lg:table-cell'],
      xl: ['hidden', 'xl:table-cell'],
      '2xl': ['hidden', '2xl:table-cell'],
    },
  },
  defaultVariants: {
    breakpoint: false,
  },
});
