import type { FC, ReactNode } from 'react';

interface TableSingleItemWrapperProps {
  children?: ReactNode;
  colSpan?: number;
  rowSpan?: number;
}

export const TableSingleItemWrapper: FC<TableSingleItemWrapperProps> = ({ children, rowSpan, colSpan }) => (
  <tbody>
    <tr>
      <td colSpan={colSpan} rowSpan={rowSpan}>
        {children}
      </td>
    </tr>
  </tbody>
);
