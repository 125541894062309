import { cva } from 'class-variance-authority';

export const stepperItemClasses = cva(
  ['flex items-center justify-center stepper-item relative h-8 w-8 rounded-full border-2 border-gray-300'],
  {
    variants: {
      isActive: {
        true: 'text-primary-600 border-primary-600',
        false: '',
      },
      isCompleted: {
        true: 'bg-primary-600 text-primary-600 border-primary-600',
        false: 'bg-white',
      },
    },
    defaultVariants: {
      isActive: false,
      isCompleted: false,
    },
  },
);
